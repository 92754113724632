export const fr = {
  no: 'Non',
  yes: 'Oui',
  userNotFound: 'Utilisateur non trouvé',
  invalidCredentials: 'Identification invalide',
  badOldPassword: 'Ancien mot de passe erroné',
  somethingWentWrong: "Quelque chose s'est mal passé",
  sww: "Quelque chose s'est mal passé",
  tooReqError: 'Trop de demandes, réessayez plus tard',
  fieldRequired: 'Champs requis',
  invalidEmail: 'Email invalide',
  passwordTooShort: 'Le mot de passe doit contenir au moins {0} caractères',
  login: 'Login',
  signIn: 'Connexion',
  email: 'Email',
  password: 'Mot de passe',
  dashboard: 'Panneau de commande',
  users: 'Utilisateurs',
  customers: 'Clients',
  managers: 'Gestionnaires',
  totalDevices: "Nombre total d'appareils",
  onlineDevices: 'Appareils en ligne',
  devicesOnAlarm: 'Appareils en alarme',
  deviceStatus: 'Statut des appareils',
  search: 'Chercher',
  mac: 'Mac',
  model: 'Modèle',
  registrationNumber: 'Numéro de série',
  alarms: 'Alarmes',
  warnings: 'Alertes',
  status: 'Statut',
  lastConnection: 'Dernière connexion',
  details: 'Détails',
  summary: 'Résumé',
  viewAll: 'Voir tout',
  customersList: 'Liste de clients',
  managerList: 'Liste de gestionnaires',
  id: 'ID',
  nameSurname: 'Nom et prénom',
  city: 'Ville',
  role: 'Fonction',
  notAuthorized: 'Pas autorisé',
  confirm: 'Confirmer',
  disconnectQuestion: 'Se déconnecter ?',
  logout: 'Déconnexion',
  menu: 'Menu',
  online: 'connecté',
  offline: 'déconnecté',
  actives: 'Actifs',
  user: 'Utilisateur',
  admin: 'Administrateur',
  technician: 'Technicien',
  alarmsHistory: 'Historique des alarmes',
  date: 'Date',
  dates: 'Dates',
  name: 'nom',
  type: 'type',
  alarm: 'Alarme',
  warning: 'Alerte',
  forgetPasswordQuestion: 'Mot de passe oublié ?',
  signUp: 'Incrivez-vous !',
  registration: 'Inscription',
  attention: 'Attention',
  ok: 'OK',
  acceptDataPrivacyAgreementWarn: 'Vous devez consentir au traitement des données',
  acceptDataMarketingAgreementWarn:
    "Sélectionnez une option pour l'utilisation de vos données à des fins de marketing",
  passwordMismatch: 'Les mots de passe ne correspondent pas',
  repeatPassword: 'Retaper le mot de passe',
  telephone: 'Téléphone',
  nation: 'Pays',
  company: 'Société',
  dataAgreement:
    'En cochant la case, vous acceptez les conditions de traitement des données personnelles conformément à la directive européenne 679/2016 et vous déclarez être majeur',
  agree: "J'accepte",
  notAgree: "Je n'accepte pas",
  marketingAgreement:
    'En cochant la case, vous acceptez que STEIEL utilise vos données personnelles à des fins de marketing',
  privacyTreatment: 'Traitement des données personnelles',
  verifyEmail: "Vérification de l'e-mail",
  emailSentToYourEmail: 'Nous avons envoyé un e-mail de vérification à :',
  emailNotReceivedQuestion: "Vous n'avez pas reçu l'e-mail ?",
  sendAgain: 'Soumettre à nouveau',
  verificationEmailSent: 'E-mail de vérification envoyé',
  lostPasswordQuestion: 'Avez-vous oublié votre mot de passe ?',
  lostPasswordInstructions:
    'Entrez votre adresse e-mail et nous vous enverrons des instructions pour configurer un nouveau mot de passe',
  resetPassword: 'Réinitialiser le mot de passe',
  goBack: 'Retourner',
  emailSentToYourAddress: 'Nous avons envoyé un e-mail à votre adresse',
  passwordChanged: 'Mot de passe changé',
  notice: 'Alerte',
  changePassword: 'Changer le mot de passe',
  settings: 'Réglages',
  add: 'Ajouter',
  profile: 'Profil',
  security: 'Sécurité',
  saveChanges: 'Sauvegarder les modifications',
  profileSavedSuccessfully: 'Profil enregistré avec succès',
  dataSavedSuccessfully: 'Données enregistrées avec succès',
  oldPassword: 'Ancien mot de passe',
  passwordChangedSuccessfully: 'Mot de passe changé avec succès',
  counterResettedSuccessfully: 'Compteur réinitialisé avec succès',
  at: 'à',
  noDataFound: 'Aucune donnée disponible',
  deviceManagement: 'Gestion des appareils',
  usersManagement: 'Gestion des utilisateurs',
  version: 'Version',
  dateAndTime: 'Date/Heure',
  place: 'Lieu',
  comeback: 'Retourner',
  setup: 'Configuration',
  default: 'Default',
  interface: 'Interface',
  temperature: 'Température',
  ph: 'pH',
  chlorine: 'Chlore',
  totalChlorine: 'Chlore total',
  freeChlorine: 'Chlore libre',
  combinedChlorine: 'Chlore combiné',
  table: 'Tableau',
  charts: 'Graphiques',
  setpoint: 'Setpoint',
  customizeMeasurements: 'Personnaliser le layout',
  active: 'Actif',
  deactivated: 'Désactivé',
  minAcceptableValue: 'Valeur min acceptable',
  maxAcceptableValue: 'Valeur max acceptable',
  outputs: 'Sorties',
  inputs: 'Entrées',
  on: 'on',
  off: 'off',
  value: 'Valeur',
  exportTable: 'Exporter tableau',
  exportAlarms: 'Exporter alarmes',
  localDateTime: 'Heure/Date (locale)',
  notes: 'Remarque',
  channel: 'Canal',
  description: 'Description',
  deviceDetails: "Détails de l'appareil",
  measure: 'Mesure',
  enable: 'Autoriser',
  unitOfMeasure: 'Unité de mesure',
  scaleStart: "Début d'échelle",
  bottomScale: "Fin d'échelle",
  minAlarm: 'Alarme min',
  maxAlarm: 'Alarme max',
  minAcceptableLimit: 'Limite min acceptable',
  maxAcceptableLimit: 'Limite max acceptable',
  decimals: 'Décimales',
  selectWidget: 'Sélectionner Widget',
  notifications: 'Avis',
  notificationsDelay: 'Délai de notification (minutes)',
  recipientList: 'Liste des destinataires',
  customizeNotification: 'Personnaliser les avis',
  apply: 'Appliquer',
  view: 'Voir',
  searchPlaceholder: 'Chercher ID, nom, lieu',
  filter: 'Filtre',
  withAlarm: 'Alarme',
  notWithAlarm: "Pas d'alarme",
  cancel: 'Annuler',
  deviceDefaultSettingsResetQuestion:
    "Voulez-vous réinitialiser l'appareil aux paramètres d'usine ?",
  samplingTime: "Temps d'échantillonnage (min)",
  timeZone: 'Décalage horaire',
  macAddress: 'Adresse mac',
  tool: 'Instrument',
  newCustomer: 'Nouveau client',
  newManager: 'Nouveau gestionnaire',
  locality: 'Lieu',
  notificationManagement: 'Gestion des avis',
  manageNotifications: 'Gérer les avis',
  sendEmail: 'Envoyer un e-mail',
  usersList: 'Liste des utilisateurs',
  modifyCustomer: 'Modifier client',
  modifyManager: 'Modifier gestionnaire',
  newUser: 'Nouveau utilisateur',
  deleteUser: "Supprimer l'utilisateur",
  notePlaceholder: 'Insérer une note',
  insertLogo: 'Insérer le logo',
  language: 'Langue',
  file: 'File',
  create: 'Créer',
  modify: 'Modifier',
  update: 'Mettre à jour',
  createNewCustomer: 'Créer un nouveau client',
  createNewManager: 'Créer un nouveau gestionnaire',
  viewer: 'Observateur',
  superTechnician: 'Administrateur n.2',
  supertechnician: 'Administrateur n.2',
  newDevice: 'Nouveau appareil',
  createNewDevice: 'Créer un nouveau appareil',
  installer: 'Installateur',
  newModule: 'Nouveau module',
  modifyDevice: 'Modifier appareil',
  searchByNamePlace: 'Chercher prénom, lieu',
  searchByNameSurnameUsername: "Chercher prénom, nom, nom d'utilisateur...",
  surname: 'Nom',
  username: "Nom d'utilisateur",
  modifyUser: 'Modifier utilisateur',
  confirmPassword: 'Confirmer le mot de passe',
  insertNote: 'Insérer une note',
  createNewUser: 'Créer un nouveau client',
  customersManagement: 'Gestion des clients',
  customer: 'Client',
  manager: 'Gestionnaire',
  send: 'Envoyer',
  modifyPassword: 'Modifier le mot de passe',
  newPassword: 'Nouveau mot de passe',
  passwordRecoveryInstructions:
    'Entrez votre email, nous vous enverrons un lien pour changer votre mot de passe',
  userProfile: 'Profil utilisateur',
  italian: 'Italien',
  english: 'Anglais',
  currentPassword: 'Mot de passe actuel',
  new: 'Nouveau',
  deviceInterfaceChartTitle: 'SwimEx Cold Plunge Pool',
  deviceInterfaceChartMobileTip:
    "Orientez l'appareil horizontalement pour une meilleure vue du graphique",
  deviceInterfaceChartTip: 'Placez le pointeur sur le graphique pour afficher les données',
  dtBodyNoMatch: 'Aucune donnée disponible',
  dtBodyTooltip: 'Trier',
  dtBodyColumnHeaderTooltip: 'Trier par',
  dtNextPage: 'Page suivante',
  dtPreviousPage: 'Page précédente',
  dtRowsPerPage: 'Lignes par page :',
  jumpToPage: 'Passer à la page :',
  dtDisplayRows: 'dei',
  dtToolbarSearch: 'Chercher',
  dtToolbarDownloadCsv: 'Télécharger CSV',
  dtToolbarPrint: 'Imprimer',
  dtToolbarViewColumns: 'Vieu colonnes',
  dtToolbarFilterTable: 'Filtrer le tableau',
  dtFilterAll: 'Tout',
  dtFilterTitle: 'Filtres',
  dtFilterReset: 'Réinitialiser',
  dtViewColumnsTitle: 'Afficher les colonnes',
  dtViewColumnsTitleAria: 'Afficher/masquer les colonnes',
  dtSelectedRowsText: 'Lignes sélectionnées',
  dtSelectedRowsDelete: 'Supprimer',
  dtSelectedRowsDeleteAria: 'Supprimer les lignes sélectionnées',
  success: 'Réussi',
  recoveryPasswordEmailSent: 'E-mail de récupération de mot de passe envoyé',
  french: 'Français',
  noInterfaceConnected: 'Aucune interface connectée',
  hthInterfaceName: 'CYCL’EAU First',
  hthProInterfaceName: 'CYCL’EAU Pro',
  input_pHLvl: 'Niveau pH',
  input_cl2Lvl: 'Niveau cloro',
  input_lvl3: 'Niveau 3',
  input_flow: 'Flow',
  input_cadOff: 'CAD (Off)',
  output_out1: 'Out 1',
  output_out2: 'Out 2',
  output_alarm: 'Relais alarme',
  alarm_lowPHLvl: 'Niveau pH bas',
  alarm_lowCl2Lvl: 'Niveau chlore bas',
  alarm_flow: 'Flow',
  alarm_onOffKey: 'Touche ON/OFF',
  alarm_inputCadOff: 'Entrée CAD Off',
  alarm_maxPHDosage: 'Dosage maxi pH',
  alarm_maxCl2Dosage: 'Dosage maxi chlore',
  alarm_startDelay: 'Démarrage retardé',
  alarm_pHMin: 'pH minimum',
  alarm_phMax: 'pH maximum',
  alarm_cl2Min: 'Cl2 minimum',
  alarm_cl2Max: 'Cl2 maximum',
  noMeasurementFound: 'Aucune mesure disponible',
  settingsSavedSuccessfully: 'Paramètres enregistrés avec succès',
  parameters: 'Paramètres',
  parameter: 'Paramètre',
  noParametersFound: 'Aucun paramètre disponible',
  setpointPHParamDesc: 'Setpoint 1 (OUT_pH)',
  setpointCl2ParamDesc: 'Setpoint 2 (OUT_Cl2)',
  minPHAlarmParamDesc: 'Alarme (alerte) min. pH',
  maxPHAlarmParamDesc: 'Alarme (alerte) max. pH',
  minFreeChlorineParamDesc: 'Alarme (alerte) min. chlore',
  maxFreeChlorineParamDesc: 'Alarme (alerte) max. chlore',
  maxCombChlorineParamDesc: 'Alarme (alerte) max. chlore combiné',
  settingTypePHParamDesc: 'Type de réglage OUT_pH',
  settingTypeCl2ParamDesc: 'Type de réglage OUT_Cl2',
  hysteresisPHParamDesc: 'Hystérèse OUT_pH',
  hysteresisCl2ParamDesc: 'Hystérèse OUT_Cl2',
  sensePHParamDesc: 'Direction pH',
  startDelayParamDesc: 'Délai au démarrage (sec)',
  flowDelayParamDesc: 'Délai après rétablissement du débit (sec)',
  maxInjectionTimeParamDesc: "Temps d'injection max. (min)",
  pHTankLvlParamDesc: 'Niveau bac pH',
  chlorineTankLvlParamDesc: 'Niveau bac chlore',
  flowSensorParamDesc: 'Capteur de débit',
  totalChlorineParamDesc: 'Chlore total',
  languageParamDesc: 'Langue',
  alarmRelayParamDesc: 'Relais alarme',
  serialLineParamDesc: 'Ligne série',
  serialLineCommSpeedParamDesc: 'Vitesse de communication de la ligne série',
  modbusCommAddrParamDesc: 'Adresse de communication Modbus',
  passwordParamDesc: 'Mot de passe',
  proportional: 'Proportionnel',
  onOff: 'On-off',
  alkaline: 'Alcalin',
  acid: 'Acide',
  spanish: 'Espagnol',
  ascii: 'Ascii',
  modbus: 'Modbus',
  min: 'min.',
  max: 'max.',
  onlineWithAlarm: 'En ligne avec alarme',
  macAddressNotFound: 'Adresse mac non trouvé',
  invalidCompanyValue: 'Valeur entreprise non valide',
  deleteDevice: "Supprimer l'appareil",
  deleteDeviceQuestion: "Supprimer l'appareil ?",
  userCreatedSuccessfully: 'Utilisateur créé avec succès',
  deleteUserQuestion: "Supprimer l'utilisateur {0} ?",
  deviceCreatedSuccessfully: 'Appareil créé avec succès',
  noMeasurementsFoundOrEnabled: 'Aucune mesure activée ou trouvée',
  companyDomain: "Domaine de l'entreprise",
  from: 'de',
  to: 'à',
  alarmsFixed: 'Alarmes résolues',
  badInputData: "Données d'entrée incorrectes",
  changesCompleted: 'Modifications terminées',
  upload: 'Charger',
  invalidFileSize: 'Taille de fichier non valide',
  invalidFileType: 'Type de fichier non valide',
  alarm_maxTimeK1: 'Temps maxi K1',
  alarm_maxTimeK2: 'Temps maxi K2',
  alarm_maxTimeK4: 'Temps maxi K4',
  alarm_maxTimeK5: 'Temps maxi K5',
  alarm_ignitionDelay: 'Délai au démarrage',
  alarm_pHStabilizer: 'Stabilization du pH',
  alarm_clock: 'Horloge',
  alarm_zeroChlorine: 'Zéro chlore',
  alarm_redox: 'Redox',
  alarm_superchlorination: 'Superchloration',
  alarm_notUsed1: 'Non utilisé 1',
  alarm_notUsed2: 'Non utilisé 2',
  alarm_notUsed3: 'Non utilisé 3',
  alarm_notUsed4: 'Non utilisé 4',
  alarm_richAssist: "Demande d'assistance",
  alarm_toolBlock: "Bloc de l'appareil",
  output_k1: 'K1',
  output_k2: 'K2',
  output_k3: 'K3',
  output_k4: 'K4',
  output_k5: 'K5',
  output_ev: 'EV',
  output_perist1: 'Périst1',
  output_perist2: 'Périst2',
  output_perist3: 'Périst3',
  output_mixer: 'Mixer 1',
  input_lvl1: 'Niveau 1',
  input_lvl2: 'Niveau 2',
  input_off: 'Entrée OFF',
  input_imp: 'Entrée IMP',
  P01MC14ParamDesc: 'Mesure associée à la sortie relais K1',
  P02MC14ParamDesc: 'Type de sortie relais K1',
  P03MC14ParamDesc: '',
  P04MC14ParamDesc: '',
  P05MC14ParamDesc: '',
  P06MC14ParamDesc: '',
  P07MC14ParamDesc: 'Alarme max temps de dosage relais K1 (h : min)',
  P08MC14ParamDesc: 'Conditions OFF relais K1',
  P09MC14ParamDesc: 'Mesure associée à la sortie relais K2',
  P10MC14ParamDesc: 'Type de sortie relais K2',
  P11MC14ParamDesc: '',
  P12MC14ParamDesc: '',
  P13MC14ParamDesc: '',
  P14MC14ParamDesc: '',
  P15MC14ParamDesc: 'Alarme max temps de dosage relais K2 (h : min)',
  P16MC14ParamDesc: 'Conditions OFF relais K2',
  P17MC14ParamDesc: '',
  P18MC14ParamDesc: '',
  P19MC14ParamDesc: '',
  P20MC14ParamDesc: '',
  P21MC14ParamDesc: '',
  P22MC14ParamDesc: '',
  P23MC14ParamDesc: '',
  P24MC14ParamDesc: '',
  P25MC14ParamDesc: '',
  P26MC14ParamDesc: '',
  P27MC14ParamDesc: '',
  P28MC14ParamDesc: '',
  P29MC14ParamDesc: '',
  P30MC14ParamDesc: '',
  P31MC14ParamDesc: '',
  P32MC14ParamDesc: '',
  P33MC14ParamDesc: '',
  P34MC14ParamDesc: '',
  P35MC14ParamDesc: '',
  P36MC14ParamDesc: '',
  P37MC14ParamDesc: '',
  P38MC14ParamDesc: '',
  P39MC14ParamDesc: '',
  P40MC14ParamDesc: '',
  P41MC14ParamDesc: '',
  P42MC14ParamDesc: '',
  P43MC14ParamDesc: '',
  P44MC14ParamDesc: '',
  P45MC14ParamDesc: '',
  P46MC14ParamDesc: '',
  P47MC14ParamDesc: '',
  P48MC14ParamDesc: '',
  P49MC14ParamDesc: '',
  P50MC14ParamDesc: '',
  P51MC14ParamDesc: '',
  P52MC14ParamDesc: '',
  P53MC14ParamDesc: '',
  P54MC14ParamDesc: '',
  P55MC14ParamDesc: '',
  P56MC14ParamDesc: '',
  P57MC14ParamDesc: '',
  P58MC14ParamDesc: '',
  P59MC14ParamDesc: '',
  P60MC14ParamDesc: '',
  P61MC14ParamDesc: '',
  P62MC14ParamDesc: '',
  P63MC14ParamDesc: '',
  P64MC14ParamDesc: '',
  P65MC14ParamDesc: '',
  P66MC14ParamDesc: '',
  P67MC14ParamDesc: '',
  P68MC14ParamDesc: '',
  P69MC14ParamDesc: '',
  P70MC14ParamDesc: '',
  P71MC14ParamDesc: '',
  P72MC14ParamDesc: '',
  P73MC14ParamDesc: '',
  P74MC14ParamDesc: '',
  P75MC14ParamDesc: '',
  P76MC14ParamDesc: '',
  P77MC14ParamDesc: '',
  P78MC14ParamDesc: '',
  P79MC14ParamDesc: '',
  P80MC14ParamDesc: '',
  P81MC14ParamDesc: '',
  P82MC14ParamDesc: '',
  P83MC14ParamDesc: '',
  P84MC14ParamDesc: '',
  P85MC14ParamDesc: '',
  P86MC14ParamDesc: '',
  P87MC14ParamDesc: '',
  P88MC14ParamDesc: '',
  P89MC14ParamDesc: '',
  P90MC14ParamDesc: '',
  P91MC14ParamDesc: '',
  P92MC14ParamDesc: '',
  P93MC14ParamDesc: '',
  P94MC14ParamDesc: '',
  notAvailable: 'Pas disponible',
  disabled: 'Désactivée',
  closingOverthresholds: 'Fermeture au dépassement de seuils',
  openingOverthreshold: 'Ouverture au dépassement de seuils',
  upward: 'En montée',
  downward: 'En déscente',
  hourlyOperation: 'Fonctionnement horaire',
  unknown: 'Inconnu',
  mc14evoPHRxCle12TcInterfaceName: 'MC14 EVO pH/RX/CLE12/T',
  mc14evoPHRxCacTcInterfaceName: 'MC14 EVO pH/RX/CAC/T',
  mc14evo_4CanaliTcInterfaceName: 'MC14 EVO 4 canali + T',
  mco14_3SezCl2InterfaceName: 'MCO14 EVO 3 sections (Cl2)',
  mco14_5SezCl2ClTClCInterfaceName: 'MCO14 EVO 5 sections (Cl2 + ClT + ClC)',
  nsp161InterfaceName: 'NOVA NSP161',
  nsp161CMinInterfaceName: 'NOVA NSP161 c/min',
  nsp161MAInterfaceName: 'NOVA NSP161 mA',
  nsp162PHRxInterfaceName: 'NOVA NSP162 pH/RX',
  nsp163ACle11_12InterfaceName: 'NOVA NSP163A CLE11/12',
  nsp163BCle16InterfaceName: 'NOVA NSP163B CLE16',
  pHRxTcInterfaceName: 'EF315 pH/RX/T',
  pHClTcInterfaceName: 'EF315 pH/CL/T',
  pHCacTcInterfaceName: 'EF315 pH/CAC/T',
  channels: 'Canaux',
  sectionMin: 'Section',
  loggedInAs: 'Authentifié comme',
  badRangeInput: 'Valeur de données de plage non respectée',
  open: 'Ouvrir',
  all: 'Toutes',
  moduleComunicationTimeoutErrDesc: "Vérifier le câblage ou l'alimentation de l'interface",
  updateParamFailed: 'Mise à jour du paramètre échoué',
  saveChangesQuestion: 'Voulez-vous vraiment enregistrer les modifications ?',
  secondaryParameters: 'Paramètres secondaires',
  P03MC14ParamDescOption1: 'Seuil à atteindre relais K1',
  P03MC14ParamDescOption2: "Valeur centrale du seuil d'alarme relais K1",
  P03MC14ParamDescOption3: 'Heure de la première activation relais K1',
  P04MC14ParamDescOption1: 'Hystérèse relais K1',
  P04MC14ParamDescOption2: 'Hystérésis au dessus et en dessous du seuil relais K1',
  P04MC14ParamDescOption3: 'Heure de la première désactivation relais K1',
  P11MC14ParamDescOption1: 'Seuil à atteindre relais K2',
  P11MC14ParamDescOption2: "Valeur centrale du seuil d'alarme relais K2",
  P11MC14ParamDescOption3: 'Heure de la première activation relais K2',
  P12MC14ParamDescOption1: 'Hystérèse relais K2',
  P12MC14ParamDescOption2: 'Hystérésis au dessus et en dessous du seuil relais K2',
  P12MC14ParamDescOption3: 'Heure de la première désactivation relais K2',
  P27MC14ParamDescOption1: 'Seuil à atteindre relais K4',
  P27MC14ParamDescOption2: "Valeur centrale du seuil d'alarme relais K4",
  P27MC14ParamDescOption3: 'Heure de la première activation relais K4',
  P28MC14ParamDescOption1: 'Hystérèse relais K4',
  P28MC14ParamDescOption2: 'Hystérésis au dessus et en dessous du seuil relais K4',
  P28MC14ParamDescOption3: 'Heure de la première désactivation relais K4',
  alarm_lvl: 'Niveau',
  alarm_extcons: 'Autorisation Ext.Cons.',
  alarm_stop: 'Stop',
  alarm_urOr: 'UR/OR',
  alarm_timer: 'Timer',
  alarm_maintenance: 'Entretien',
  alarm_autoadesco: 'Auto amorçage',
  alarm_internalTemp: 'Température interne',
  alarm_maxDoseTime: 'Max temps de dosage',
  alarm_maxAccPulses: "Max d'impulsions accumulées",
  alarm_sampleWater: "Eau d'échantillon",
  alarm_clockKO: 'Horloge KO',
  output_magnetStatus: "État de l'aimant",
  output_relayAlarm: "Relais D'État",
  input_lvl: 'Niveau',
  input_extcons: 'Autorisation Ext.Cons.',
  P01NSPParamDesc: 'Langue',
  P02NSPParamDesc: '',
  P03NSPParamDesc: '',
  P04NSPParamDesc: '',
  P05NSPParamDesc: '',
  P06NSPParamDesc: '',
  P07NSPParamDesc: 'Fréquence maxi',
  P08NSPParamDesc: '',
  P09NSPParamDesc: '',
  P10NSPParamDesc: '',
  P11NSPParamDesc: 'Setpoint',
  P12NSPParamDesc: 'Hystérésis / Bande proportionnelle',
  P13NSPParamDesc: '',
  P14NSPParamDesc: '',
  P15NSPParamDesc: '',
  P16NSPParamDesc: '',
  P17NSPParamDesc: '',
  P18NSPParamDesc: '',
  P19NSPParamDesc: '',
  P20NSPParamDesc: '',
  P21NSPParamDesc: '',
  P22NSPParamDesc: '',
  P23NSPParamDesc: '',
  P24NSPParamDesc: '',
  P25NSPParamDesc: '',
  P26NSPParamDesc: '',
  P27NSPParamDesc: '',
  P28NSPParamDesc: '',
  P29NSPParamDesc: '',
  P30NSPParamDesc: '',
  P31NSPParamDesc: '',
  P32NSPParamDesc: '',
  P33NSPParamDesc: '',
  P34NSPParamDesc: '',
  P35NSPParamDesc: '',
  P36NSPParamDesc: '',
  P37NSPParamDesc: '',
  P38NSPParamDesc: '',
  P39NSPParamDesc: '',
  P40NSPParamDesc: '',
  P41NSPParamDesc: '',
  P42NSPParamDesc: '',
  P43NSPParamDesc: '',
  P44NSPParamDesc: '',
  P45NSPParamDesc: '',
  P46NSPParamDesc: '',
  P47NSPParamDesc: '',
  P48NSPParamDesc: '',
  P49NSPParamDesc: '',
  P50NSPParamDesc: '',
  P51NSPParamDesc: '',
  P52NSPParamDesc: '',
  P53NSPParamDesc: '',
  P54NSPParamDesc: '',
  P55NSPParamDesc: '',
  P56NSPParamDesc: '',
  P57NSPParamDesc: '',
  P58NSPParamDesc: '',
  P59NSPParamDesc: '',
  P60NSPParamDesc: '',
  P61NSPParamDesc: '',
  P62NSPParamDesc: '',
  P63NSPParamDesc: '',
  P64NSPParamDesc: '',
  P65NSPParamDesc: '',
  P66NSPParamDesc: '',
  P67NSPParamDesc: '',
  P68NSPParamDesc: '',
  P69NSPParamDesc: '',
  P70NSPParamDesc: '',
  P71NSPParamDesc: '',
  P72NSPParamDesc: '',
  P73NSPParamDesc: '',
  P74NSPParamDesc: '',
  P75NSPParamDesc: '',
  P76NSPParamDesc: '',
  P77NSPParamDesc: '',
  P78NSPParamDesc: '',
  P79NSPParamDesc: '',
  P80NSPParamDesc: '',
  P81NSPParamDesc: '',
  P82NSPParamDesc: '',
  P83NSPParamDesc: '',
  P84NSPParamDesc: '',
  P85NSPParamDesc: '',
  P86NSPParamDesc: '',
  P87NSPParamDesc: '',
  P88NSPParamDesc: '',
  P89NSPParamDesc: '',
  P90NSPParamDesc: '',
  P91NSPParamDesc: '',
  P92NSPParamDesc: '',
  P93NSPParamDesc: '',
  P94NSPParamDesc: '',
  P95NSPParamDesc: '',
  alarm_lvl1: 'Niveau 1',
  alarm_lvl2: 'Niveau 2',
  alarm_maxDosageTimeOut1: 'Temps de dosage max Out 1',
  alarm_maxDosageTimeOut2: 'Temps de dosage max Out 2',
  alarm_urOrMeasure1: 'UR/OR Mesure 1',
  alarm_urOrMeasure2: 'UR/OR Mesure 2',
  alarm_maintenancePump1: 'Entretien de la pompe 1',
  alarm_maintenancePump2: 'Entretien de la pompe 2',
  alarm_recalibrateChlorineProbeAfter24h: 'Recalibrer la sonde de chlore après 24 heures',
  alarm_halvedSetpointForBadCalib: 'Setpoint réduit de moitié',
  input_stop: 'Stop',
  input_ignitionDelay: 'Délai au démarrage',
  input_pHStabilizer: 'Stabilization du pH',
  input_maintenancePump1: 'Entretien de la pompe 1',
  input_maintenancePump2: 'Entretien de la pompe 2',
  input_recalibrateChlorineProbeAfter24h: 'Recalibrer la sonde de chlore après 24 heures',
  input_halvedSetpointForBadCalib: 'Setpoint réduit de moitié',
  P01EFParamDesc: '',
  P02EFParamDesc: '',
  P03EFParamDesc: 'Setpoint (Out 1)',
  P04EFParamDesc: 'Hystérésis / Bande proportionnelle (Out 1)',
  P05EFParamDesc: 'Fréquence maxi (Out 1)',
  P06EFParamDesc: '',
  P07EFParamDesc: '',
  P08EFParamDesc: '',
  P09EFParamDesc: '',
  P10EFParamDesc: 'Setpoint (Out 2)',
  P11EFParamDesc: 'Hystérésis / Bande proportionnelle (Out 2)',
  P12EFParamDesc: 'Fréquence maxi (Out 2)',
  P13EFParamDesc: '',
  P14EFParamDesc: '',
  P15EFParamDesc: '',
  P16EFParamDesc: '',
  P17EFParamDesc: '',
  P18EFParamDesc: '',
  P19EFParamDesc: '',
  P20EFParamDesc: '',
  P21EFParamDesc: '',
  P22EFParamDesc: '',
  P23EFParamDesc: '',
  P24EFParamDesc: '',
  P25EFParamDesc: '',
  P26EFParamDesc: '',
  P27EFParamDesc: '',
  P28EFParamDesc: '',
  P29EFParamDesc: '',
  P30EFParamDesc: '',
  P31EFParamDesc: '',
  P32EFParamDesc: '',
  P33EFParamDesc: '',
  P34EFParamDesc: '',
  eventsLog: 'LOG des événements',
  events: 'Événements',
  alarm_new_stop: 'Strumento OFF',
  alarm_new_inputCadOff: 'Ingresso CAD (OFF)',
  alarm_new_flow: 'Allarme Flow',
  alarm_new_urOr: 'Allarme UR/OR',
  alarm_new_lowPHLvl: 'Allarme Min livello pH',
  alarm_new_lowCl2Lvl: 'Allarme Min livello Cl2',
  alarm_new_maxPHDosage: 'Allarme Max dosaggio pH',
  alarm_new_maxCl2Dosage: 'Allarme Max dosaggio Cl2',
  alarm_new_pHMin: 'Avviso minimo pH',
  alarm_new_phMax: 'Avviso massimo pH',
  alarm_new_cl2Min: 'Avviso minimo Cl2',
  alarm_new_cl2Max: 'Avviso massimo Cl2',
  alarm_new_clock: 'Timer',
  alarm_new_maxTimeK1: 'Allarme Max TK1',
  alarm_new_maxTimeK2: 'Allarme Max TK2',
  alarm_new_maxTimeK4: 'Allarme Max TK4',
  alarm_new_maxTimeK5: 'Allarme Max TK5',
  alarm_new_redox: 'Allarme Redox',
  alarm_new_zeroChlorine: 'Allarme 0 Cl2',
  output_mixer2: 'Mixer 2',
  alarm_extConsensus: 'Consenso esterno',
  alarm_lvl1MinAlarm: 'Allarme Min livello 1',
  alarm_lvl2MinAlarm: 'Allarme Min livello 2',
  alarm_lvl3MinAlarm: 'Allarme Min livello 3',
  alarm_off: 'Off',
  alarm_new_maintenance: 'Rich. Manutenzione',
  alarm_new_timer: 'Timer control',
  alarm_new_lvl: 'Allarme Livello',
  alarm_new_extcons: 'Allarme Ext.Cons',
  alarm_new_internalTemp: 'Allarme temperatura interna',
  alarm_new_clockKO: 'Allarme Orologio KO',
  alarm_new_sampleWater: 'Sample water',
  alarm_new_maxDoseTime: 'Allarme Max Dosaggio',
  alarm_new_maintenancePump1: 'Manutenzione P1',
  alarm_new_maintenancePump2: 'Manutenzione P2',
  alarm_new_urOrMeasure1: 'Allarme UR/OR Misura 1',
  alarm_new_urOrMeasure2: 'Allarme UR/OR Misura 2',
  alarm_new_lvl1: 'Allarme Livello 1',
  alarm_new_lvl2: 'Allarme Livello 2',
  alarm_new_maxDosageTimeOut1: 'Allarme MAX T P1',
  alarm_new_maxDosageTimeOut2: 'Allarme MAX T P2',
  statuses: 'Statuts',
  theme: 'thème',
  toolStatus: "État de l'outil",
  minWarningLimit: 'Valeur idéale min',
  maxWarningLimit: 'Valeur idéale max',
  canNotSaveOfflineOrErrModule:
    'les paramètres ne peuvent pas être enregistrés lorsque le module est hors ligne ou présente une erreur de communication',
  ew2PoolInterfaceName: 'Evolution EW² Pool',
  ew2Pool_pHOrpTcInterfaceName: 'EW² Pool pH/ORP/T',
  ew2Pool_pHCacTcInterfaceName: 'EW² Pool pH/CAC/T',
  ew2Pool_pHCleTcInterfaceName: 'EW² Pool pH/CLE/T',
  nova2Pool_pHOrpTcInterfaceName: 'Nova² Pool pH/ORP/T',
  nova2Pool_pHCacTcInterfaceName: 'Nova² Pool pH/CAC/T',
  nova2Pool_pHCleTcInterfaceName: 'Nova² Pool pH/CLE/T',
  alarm_minThresholdM1: 'E01 soglia minima M1',
  alarm_maxThresholdM1: 'E02 soglia massima M1',
  alarm_lvl1m1: 'E03 level 1 M1',
  alarm_lvl2m1: 'E04 level 2 M1',
  alarm_underRangeM1: 'E05 under range M1',
  alarm_overRangeM1: 'E06 over range M1',
  alarm_maxDosageM1: 'E07 massimo dosaggio M1',
  alarm_minThresholdM2: 'E09 soglia minima M2',
  alarm_maxThresholdM2: 'E10 soglia massima M2',
  alarm_lvl1m2: 'E11 level 1 M2',
  alarm_lvl2m2: 'E12 level 2 M2',
  alarm_underRangeM2: 'E13 under range M2',
  alarm_overRangeM2: 'E14 over range M2',
  alarm_maxDosageM2: 'E15 massimo dosaggio M2',
  alarm_sampleFlow: 'E18 flusso campione',
  alarm_pHPriority: 'E19 pH priority',
  output_pfmM1: 'DO1 - PFM M1',
  output_pfmM2: 'DO2 - PFM M2',
  output_outM1: 'DO10 - OUT M1',
  output_flocculant: 'DO7 - FLOCCULANTE',
  output_outM2: 'DO11 - OUT M2',
  output_antiAlgae: 'DO8 - ANTIALGA',
  output_poolRelayAlarm: 'DO9 - RELE’ ALLARME',
  input_lvl1m1: 'DI1 - Livello 1 M1',
  input_lvl2m1: 'DI2 - Livello 2 M1',
  input_externalConsent: 'DI3 - Consenso esterno',
  input_ecoMode: 'DI4 - Eco mode',
  input_sampleWater: 'DI6 - Samplewater',
  input_lvl1m2: 'DI8 - Livello 1 M2',
  input_lvl2m2: 'DI9 - Livello 2 M2',
  setpointM1OnOffParamDesc: 'Setpoint M1 On/Off',
  propBandM1OnOffParamDesc: 'Isteresi M1 On/Off',
  dosageDirM1OnOffParamDesc: 'Direzione Dosaggio M1 On/Off',
  maxDosageTimeM1OnOffParamDesc: 'Tempo MAX Dosaggio M1 On/Off',
  setpointM1PwmParamDesc: 'Setpoint M1 PWM',
  propBandM1PwmParamDesc: 'Banda Proporzionale M1 PWM',
  dosageDirM1PwmParamDesc: 'Direzione Dosaggio M1 PWM',
  maxDosageTimeM1PwmParamDesc: 'Tempo MAX Dosaggio M1 PWM',
  setpointM2OnOffParamDesc: 'Setpoint M2 On/Off',
  setpointM2PwmParamDesc: 'Setpoint M2 PWM',
  setpointM1PfmParamDesc: 'Setpoint M1 PFM',
  propBandM1PfmParamDesc: 'Banda Proporzionale M1 PFM',
  dosageDirM1PfmParamDesc: 'Direzione Dosaggio M1 PFM',
  maxDosageTimeM1PfmParamDesc: 'Tempo MAX Dosaggio M1 PFM',
  minFreqM1ParamDesc: 'Frequenza minima M1',
  maxFreqM1ParamDesc: 'Frequenza massima M1',
  minLimitM1ParamDesc: 'Limite MIN M1',
  maxLimitM1ParamDesc: 'Limite MAX M1',
  propBandM2OnOffParamDesc: 'Isteresi M2 On/Off',
  dosageDirM2OnOffParamDesc: 'Direzione Dosaggio M2 On/Off',
  maxDosageTimeM2OnOffParamDesc: 'Tempo MAX Dosaggio M2 On/Off',
  propBandM2PwmParamDesc: 'Banda Proporzionale M2 PWM',
  dosageDirM2PwmParamDesc: 'Direzione Dosaggio M2 PWM',
  maxDosageTimeM2PwmParamDesc: 'Tempo MAX Dosaggio M2 PWM',
  setpointM2PfmParamDesc: 'Setpoint M2 PFM',
  propBandM2PfmParamDesc: 'Banda Proporzionale M2 PFM',
  dosageDirM2PfmParamDesc: 'Direzione Dosaggio M2 PFM',
  maxDosageTimeM2PfmParamDesc: 'Tempo MAX Dosaggio M2 PFM',
  minFreqM2ParamDesc: 'Frequenza minima M2',
  maxFreqM2ParamDesc: 'Frequenza massima M2',
  setpointEcoModeParamDesc: 'Setpoint Eco/Boost mode',
  udmTempParamDesc: 'UDM Temperature',
  maxLimitM2ParamDesc: 'Limite MAX M2',
  minLimitM2ParamDesc: 'Limite MIN M2',
  acidification: 'acidification',
  alkalization: 'alcalinisation',
  dechlorination: 'déchloration',
  chlorination: 'chloration',
  output_out230VM2: 'DO6 - OUT_230V M2',
  output_out230VM1: 'DO5 - OUT_230V M1',
  output_phMagnet: 'stato magnete pH',
  output_clMagnet: 'stato magnete Cl',
};
