export const es = {
  no: 'No',
  yes: 'Sí',
  userNotFound: 'Usuario no encontrado',
  invalidCredentials: 'Credenciales no válidas',
  badOldPassword: 'Contraseña antigua incorrecta',
  somethingWentWrong: 'Algo salió mal',
  sww: 'Algo salió mal',
  tooReqError: 'Demasiadas solicitudes, vuelva a intentar más tarde',
  fieldRequired: 'Campo requerido',
  invalidEmail: 'Email inválido',
  passwordTooShort: 'La contraseña debe contener al menos {0} caracteres',
  login: 'Acceso',
  signIn: 'Acceder',
  email: 'Email',
  password: 'Contraseña',
  dashboard: 'Panel',
  users: 'Usuarios',
  customers: 'Clientes',
  managers: 'Gerentes',
  totalDevices: 'Dispositivos totales',
  onlineDevices: 'Dispositivos en línea',
  devicesOnAlarm: 'Dispositivos en alarma',
  deviceStatus: 'Etado de los dispositivos',
  search: 'Buscar',
  mac: 'Mac',
  model: 'Modelo',
  registrationNumber: 'Número de serie',
  alarms: 'Alarmas',
  warnings: 'Advertencias',
  status: 'Estado',
  lastConnection: 'Última conexión',
  details: 'Detalles',
  summary: 'Resumen',
  viewAll: 'Ver todo',
  customersList: 'Lista clientes',
  managerList: 'Lista gerentes',
  id: 'ID',
  nameSurname: 'Nombre y apellido',
  city: 'Ciudad',
  role: 'Role',
  notAuthorized: 'No autorizado',
  confirm: 'Confirmar',
  disconnectQuestion: '¿Cerrar sesión?',
  logout: 'Cerrar sesión',
  menu: 'Menú',
  online: 'en línea',
  offline: 'desconectado',
  actives: 'Activos',
  user: 'Usuario',
  admin: 'Admministrador',
  technician: 'Técnico',
  alarmsHistory: 'Historial de alarmas',
  date: 'Fecha',
  dates: 'Fechas',
  name: 'nombre',
  type: 'tipo',
  alarm: 'Alarma',
  warning: 'Advertencia',
  forgetPasswordQuestion: '¿Has olvidado tu contraseña?',
  signUp: 'Iniciar sesión',
  registration: 'Registro',
  attention: 'Atención',
  ok: 'ok',
  acceptDataPrivacyAgreementWarn: 'Debes consentir el tratamiento de datos',
  acceptDataMarketingAgreementWarn:
    'Seleccione una opción par el uso de todos los datos con fines de marketing',
  passwordMismatch: 'Las contraseñas no coinciden',
  repeatPassword: 'Vuelva a escribir la contraseña',
  telephone: 'Teléfono',
  nation: 'País',
  company: 'Empresa',
  dataAgreement:
    'Al marcar la casilla, accepta las condiciones para el tratamiento de datos personales según la directiva EU 679/2016 y declaras que eres mayor de edad',
  agree: 'Acepto',
  notAgree: 'No acepto',
  marketingAgreement:
    'Al marcar la casilla, accepta que STEIEL utilice sus datos personales con fines de marketing',
  privacyTreatment: 'Tratamiento de datos personales',
  verifyEmail: 'Verificación de email',
  emailSentToYourEmail: 'Hemos enviado un correo electrónico de verificación a:',
  emailNotReceivedQuestion: '¿No recibiste el correo electrónico?',
  sendAgain: 'Reenviar',
  verificationEmailSent: 'Email de verificación enviada',
  lostPasswordQuestion: '¿Has olvidado tu contraseña?',
  lostPasswordInstructions:
    'Ingrese su dirección de correo electrónico y le enviaremos instrucciones para configurar una nueva contraseña',
  resetPassword: 'Restablecer la contraseña',
  goBack: 'Regrese',
  emailSentToYourAddress: 'Hemos enviado un correo electrónico a tu dirección',
  passwordChanged: 'Contraseña cambiada',
  notice: 'Advertencia',
  changePassword: 'Editar contraseña',
  settings: 'Ajustes',
  add: 'Agregar',
  profile: 'Perfil',
  security: 'Seguridad',
  saveChanges: 'Guardar cambios',
  profileSavedSuccessfully: 'Perfil guardado con éxito',
  dataSavedSuccessfully: 'Datos guardados con éxito',
  oldPassword: 'Contraseña antigua',
  passwordChangedSuccessfully: 'Contraseña cambiada con éxito',
  counterResettedSuccessfully: 'Contador reiniciado con éxito',
  at: 'a',
  noDataFound: 'Ningún dato encontrado',
  deviceManagement: 'Administrador de dispositivos',
  usersManagement: 'Administrador de usuarios',
  version: 'Versión',
  dateAndTime: 'Fecha/Hora',
  place: 'Lugar',
  comeback: 'Regrese',
  setup: 'Configuración',
  default: 'Default',
  interface: 'Interfaz',
  temperature: 'Temperatura',
  ph: 'pH',
  chlorine: 'Cloro',
  totalChlorine: 'Cloro total',
  freeChlorine: 'Cloro libre',
  combinedChlorine: 'Cloro combinado',
  table: 'Tabla',
  charts: 'Gráficos',
  setpoint: 'Setpoint',
  customizeMeasurements: 'Personalizar layout',
  active: 'Activo',
  deactivated: 'Desactivado',
  minAcceptableValue: 'Valor mín aceptable',
  maxAcceptableValue: 'Valor máx aceptable',
  outputs: 'Salidas',
  inputs: 'Entradas',
  on: 'on',
  off: 'off',
  value: 'Valor',
  exportTable: 'Exportar tabla',
  exportAlarms: 'Exportar alarmas',
  localDateTime: 'Hora/Fecha (local)',
  notes: 'Nota',
  channel: 'Canal',
  description: 'Descripción',
  deviceDetails: 'Detalles dispositivo',
  measure: 'Medición',
  enable: 'Habilitar',
  unitOfMeasure: 'Unidad de medida',
  scaleStart: 'Inicio escala',
  bottomScale: 'Fin de escala',
  minAlarm: 'Alarma mín',
  maxAlarm: 'Alarma máx',
  minAcceptableLimit: 'Límite mín aceptable',
  maxAcceptableLimit: 'Límite máx aceptable',
  decimals: 'Decimales',
  selectWidget: 'Seleccionar Widget',
  notifications: 'Notificaciones',
  notificationsDelay: 'Retraso de notificación (minutos)',
  recipientList: 'Lista de recipientes',
  customizeNotification: 'Personalizar notificación',
  apply: 'Aplicar',
  view: 'Ver',
  searchPlaceholder: 'Buscar ID, nombre, lugar',
  filter: 'Filtro',
  withAlarm: 'En alarma',
  notWithAlarm: 'No en alarma',
  cancel: 'Cancelar',
  deviceDefaultSettingsResetQuestion:
    '¿Desea restablecer el dispositivo a la configuración predeterminada?',
  samplingTime: 'Tiempo de muestreo (min)',
  timeZone: 'Descompensación horaria',
  macAddress: 'Dirección mac',
  tool: 'Instrumento',
  newCustomer: 'Nuevo cliente',
  newManager: 'Nuevo gerente',
  locality: 'Ubicación',
  notificationManagement: 'Gestión de notificaciones',
  manageNotifications: 'Administrar notificacioes',
  sendEmail: 'Envia email',
  usersList: 'Lista de usuarios',
  modifyCustomer: 'Editar cliente',
  modifyManager: 'Editar gerente',
  newUser: 'Nuevo usuario',
  deleteUser: 'Cancelar usuario',
  notePlaceholder: 'Insertar nota',
  insertLogo: 'Insertar lugar',
  language: 'Idioma',
  file: 'Archivo',
  create: 'Crear',
  modify: 'Editar',
  update: 'Actualizar',
  createNewCustomer: 'Crear nuevo cliente',
  createNewManager: 'Crear nuevo gerente',
  viewer: 'Espectador',
  superTechnician: 'Administrador n.2',
  supertechnician: 'Administrador n.2',
  newDevice: 'Nuevo dispositivo',
  createNewDevice: 'Crear nuevo dispositivo',
  installer: 'Instalador',
  newModule: 'Nuevo modulo',
  modifyDevice: 'Editar dispositivo',
  searchByNamePlace: 'Buscar nombre, ubicación',
  searchByNameSurnameUsername: 'Buscar nombre, apellido, nombre de usuario...',
  surname: 'Apellido',
  username: 'Nombre de usuario',
  modifyUser: 'Editar usuario',
  confirmPassword: 'Confirmar contraseña',
  insertNote: 'Insertar nota',
  createNewUser: 'Crear nuevo utente',
  customersManagement: 'Administración de clientes',
  customer: 'Cliente',
  manager: 'Gerente',
  send: 'Enviar',
  modifyPassword: 'Editar contraseña',
  newPassword: 'Nueva contraseña',
  passwordRecoveryInstructions:
    'Ingrese su correo electrónico, le enviaremos un enlace para cambiar su contraseña',
  userProfile: 'Perfil de usuario',
  italian: 'Italiano',
  english: 'Inglés',
  currentPassword: 'Contraseña actual',
  new: 'Nuevo',
  deviceInterfaceChartTitle: 'SwimEx Cold Plunge Pool',
  deviceInterfaceChartMobileTip:
    'Oriente el dispositivo horizontalmente para una mejor vista del gráfico',
  deviceInterfaceChartTip: 'Coloque el puntero sobre el gráfico para ver los datos',
  dtBodyNoMatch: 'Ningún dato encontrado',
  dtBodyTooltip: 'Ordenar',
  dtBodyColumnHeaderTooltip: 'Ordenar por',
  dtNextPage: 'Página siguiente',
  dtPreviousPage: 'Página anterior',
  dtRowsPerPage: 'Líneas por página:',
  jumpToPage: 'Saltar a la página:',
  dtDisplayRows: 'de',
  dtToolbarSearch: 'Buscar',
  dtToolbarDownloadCsv: 'Descargar CSV',
  dtToolbarPrint: 'Imprimir',
  dtToolbarViewColumns: 'Vista de columnas',
  dtToolbarFilterTable: 'Filtrar tabla',
  dtFilterAll: 'Todo',
  dtFilterTitle: 'Filtros',
  dtFilterReset: 'Reiniciar',
  dtViewColumnsTitle: 'Mostrar columnas',
  dtViewColumnsTitleAria: 'Mostrar/ocultar columnas',
  dtSelectedRowsText: 'Líneas seleccionadas',
  dtSelectedRowsDelete: 'Cancelar',
  dtSelectedRowsDeleteAria: 'Cancelar líneas seleccionadas',
  success: 'Éxito',
  recoveryPasswordEmailSent: 'Email para recuperación de contraseña enviado',
  french: 'Francés',
  noInterfaceConnected: 'Ninguna interfaz conectada',
  hthInterfaceName: 'CYCL’EAU First',
  hthProInterfaceName: 'CYCL’EAU Pro',
  input_pHLvl: 'Nivel pH',
  input_cl2Lvl: 'Nivel Cl2',
  input_lvl3: 'Nivel 3',
  input_flow: 'Flow',
  input_cadOff: 'CAD (Off)',
  output_out1: 'Out 1',
  output_out2: 'Out 2',
  output_alarm: 'Relé alarma',
  alarm_lowPHLvl: 'Nivel pH bajo',
  alarm_lowCl2Lvl: 'Nivel Cl2 bajo',
  alarm_flow: 'Flow',
  alarm_onOffKey: 'Botón ON/OFF',
  alarm_inputCadOff: 'Entrada CAD Off',
  alarm_maxPHDosage: 'Máx dosificación pH',
  alarm_maxCl2Dosage: 'Máx dosificación Cl2',
  alarm_startDelay: 'Puesta en marcha demorada',
  alarm_pHMin: 'pH mínimo',
  alarm_phMax: 'pH máximo',
  alarm_cl2Min: 'Cl2 mínimo',
  alarm_cl2Max: 'Cl2 máximo',
  noMeasurementFound: 'Ninguna medición encontrada',
  settingsSavedSuccessfully: 'Configuración guardada con éxito',
  parameters: 'Parámetros',
  parameter: 'Parámetro',
  noParametersFound: 'Ningún parámetro encontrado',
  setpointPHParamDesc: 'Setpoint 1 (OUT_pH)',
  setpointCl2ParamDesc: 'Setpoint 2 (OUT_Cl2)',
  minPHAlarmParamDesc: 'Alarma (advertencia) mín. pH',
  maxPHAlarmParamDesc: 'Alarma (advertencia) máx. pH',
  minFreeChlorineParamDesc: 'Alarma (advertencia) mín. cloro',
  maxFreeChlorineParamDesc: 'Alarma (advertencia) máx. cloro',
  maxCombChlorineParamDesc: 'Alarma (advertencia) máx. cloro combinado',
  settingTypePHParamDesc: 'Tipo de ajuste OUT_pH',
  settingTypeCl2ParamDesc: 'Tipo de ajuste OUT_Cl2',
  hysteresisPHParamDesc: 'Histéresis OUT_pH',
  hysteresisCl2ParamDesc: 'Histéresis OUT_Cl2',
  sensePHParamDesc: 'Dirección pH',
  startDelayParamDesc: 'Retraso a la puesta en marcha (segundos)',
  flowDelayParamDesc: 'Retraso después de restaurar el flujo (segundos)',
  maxInjectionTimeParamDesc: 'Tiempo de inyección máx (min)',
  pHTankLvlParamDesc: 'Nivel tanque pH',
  chlorineTankLvlParamDesc: 'Nivel tanque cloro',
  flowSensorParamDesc: 'Sensor de caudal',
  totalChlorineParamDesc: 'Cloro total',
  languageParamDesc: 'Idioma',
  alarmRelayParamDesc: 'Relé de alarma',
  serialLineParamDesc: 'Línea serial',
  serialLineCommSpeedParamDesc: 'Velocidad de comunicación de la línea serial',
  modbusCommAddrParamDesc: 'Dirección de comunicación Modbus',
  passwordParamDesc: 'Contraseña',
  proportional: 'Proporcional',
  onOff: 'On-off',
  alkaline: 'Alcalino',
  acid: 'Ácido',
  spanish: 'Español',
  ascii: 'Ascii',
  modbus: 'Modbus',
  min: 'mín',
  max: 'máx',
  onlineWithAlarm: 'En línea con alarma',
  macAddressNotFound: 'Dirección mac no encontrado',
  invalidCompanyValue: 'Valor empresa no válido',
  deleteDevice: 'elimina dispositivo',
  deleteDeviceQuestion: 'Cancelar el dispositivo?',
  userCreatedSuccessfully: 'Usuario creado con éxito',
  deleteUserQuestion: 'Cancelar el usuario {0}?',
  deviceCreatedSuccessfully: 'Dispositivo creado con éxito',
  noMeasurementsFoundOrEnabled: 'Ninguna medición habilitada o encontrada',
  companyDomain: 'Dominio de la empresa',
  from: 'desde',
  to: 'a',
  alarmsFixed: 'Alarmas resueltas',
  badInputData: 'Datos de entrada incorrectos',
  changesCompleted: 'Ediciones completadas',
  upload: 'Cargar',
  invalidFileSize: 'Tamaño de archivo no válido',
  invalidFileType: 'Tipo de archivo no válido',
  alarm_maxTimeK1: 'Tiempo máximo K1',
  alarm_maxTimeK2: 'Tiempo máximo K2',
  alarm_maxTimeK4: 'Tiempo máximo K4',
  alarm_maxTimeK5: 'Tiempo máximo K5',
  alarm_ignitionDelay: 'Retraso puesta en marcha',
  alarm_pHStabilizer: 'Estabilización pH',
  alarm_clock: 'Reloj',
  alarm_zeroChlorine: 'Cero cloro',
  alarm_redox: 'Redox',
  alarm_superchlorination: 'Supercloración',
  alarm_notUsed1: 'No utilizado 1',
  alarm_notUsed2: 'No utilizado 2',
  alarm_notUsed3: 'No utilizado 3',
  alarm_notUsed4: 'No utilizado 4',
  alarm_richAssist: 'Solicitar asistencia',
  alarm_toolBlock: 'Bloque del instrumento',
  output_k1: 'k1',
  output_k2: 'k2',
  output_k3: 'k3',
  output_k4: 'k4',
  output_k5: 'k5',
  output_ev: 'ev',
  output_perist1: 'Perist1',
  output_perist2: 'Perist2',
  output_perist3: 'Perist3',
  output_mixer: 'Mixer 1',
  input_lvl1: 'Nivel 1',
  input_lvl2: 'Nivel 2',
  input_off: 'Entrada OFF',
  input_imp: 'Entrada IMP',
  P01MC14ParamDesc: 'Medición asociada a la sortida relé K1',
  P02MC14ParamDesc: 'Tipo sortida relé K1',
  P03MC14ParamDesc: '',
  P04MC14ParamDesc: '',
  P05MC14ParamDesc: '',
  P06MC14ParamDesc: '',
  P07MC14ParamDesc: 'Alarma máx. tiempo de dosificación relé K1 (h : min)',
  P08MC14ParamDesc: 'Condiciones de OFF relé K1',
  P09MC14ParamDesc: 'Medición asociada a la sortida relé K2',
  P10MC14ParamDesc: 'Tipo sortida relé K2',
  P11MC14ParamDesc: '',
  P12MC14ParamDesc: '',
  P13MC14ParamDesc: '',
  P14MC14ParamDesc: '',
  P15MC14ParamDesc: 'Alarma máx. tiempo de dosificación relé K2 (h : min)',
  P16MC14ParamDesc: 'Condiciones de OFF relé K2',
  P17MC14ParamDesc: '',
  P18MC14ParamDesc: '',
  P19MC14ParamDesc: '',
  P20MC14ParamDesc: '',
  P21MC14ParamDesc: '',
  P22MC14ParamDesc: '',
  P23MC14ParamDesc: '',
  P24MC14ParamDesc: '',
  P25MC14ParamDesc: '',
  P26MC14ParamDesc: '',
  P27MC14ParamDesc: '',
  P28MC14ParamDesc: '',
  P29MC14ParamDesc: '',
  P30MC14ParamDesc: '',
  P31MC14ParamDesc: '',
  P32MC14ParamDesc: '',
  P33MC14ParamDesc: '',
  P34MC14ParamDesc: '',
  P35MC14ParamDesc: '',
  P36MC14ParamDesc: '',
  P37MC14ParamDesc: '',
  P38MC14ParamDesc: '',
  P39MC14ParamDesc: '',
  P40MC14ParamDesc: '',
  P41MC14ParamDesc: '',
  P42MC14ParamDesc: '',
  P43MC14ParamDesc: '',
  P44MC14ParamDesc: '',
  P45MC14ParamDesc: '',
  P46MC14ParamDesc: '',
  P47MC14ParamDesc: '',
  P48MC14ParamDesc: '',
  P49MC14ParamDesc: '',
  P50MC14ParamDesc: '',
  P51MC14ParamDesc: '',
  P52MC14ParamDesc: '',
  P53MC14ParamDesc: '',
  P54MC14ParamDesc: '',
  P55MC14ParamDesc: '',
  P56MC14ParamDesc: '',
  P57MC14ParamDesc: '',
  P58MC14ParamDesc: '',
  P59MC14ParamDesc: '',
  P60MC14ParamDesc: '',
  P61MC14ParamDesc: '',
  P62MC14ParamDesc: '',
  P63MC14ParamDesc: '',
  P64MC14ParamDesc: '',
  P65MC14ParamDesc: '',
  P66MC14ParamDesc: '',
  P67MC14ParamDesc: '',
  P68MC14ParamDesc: '',
  P69MC14ParamDesc: '',
  P70MC14ParamDesc: '',
  P71MC14ParamDesc: '',
  P72MC14ParamDesc: '',
  P73MC14ParamDesc: '',
  P74MC14ParamDesc: '',
  P75MC14ParamDesc: '',
  P76MC14ParamDesc: '',
  P77MC14ParamDesc: '',
  P78MC14ParamDesc: '',
  P79MC14ParamDesc: '',
  P80MC14ParamDesc: '',
  P81MC14ParamDesc: '',
  P82MC14ParamDesc: '',
  P83MC14ParamDesc: '',
  P84MC14ParamDesc: '',
  P85MC14ParamDesc: '',
  P86MC14ParamDesc: '',
  P87MC14ParamDesc: '',
  P88MC14ParamDesc: '',
  P89MC14ParamDesc: '',
  P90MC14ParamDesc: '',
  P91MC14ParamDesc: '',
  P92MC14ParamDesc: '',
  P93MC14ParamDesc: '',
  P94MC14ParamDesc: '',
  notAvailable: 'No disponible',
  disabled: 'Deshabilitada',
  closingOverthresholds: 'Cierre al superar los umbrales',
  openingOverthreshold: 'Apertura al superar los umbrales',
  upward: 'Cuesta harriba',
  downward: 'Cuesta abajo',
  hourlyOperation: 'Operción por horas',
  unknown: 'Desconocido',
  mc14evoPHRxCle12TcInterfaceName: 'MC14 EVO pH/RX/CLE12 + T°C',
  mc14evoPHRxCacTcInterfaceName: 'MC14 EVO pH/RX/CAC + T°C',
  mc14evo_4CanaliTcInterfaceName: 'MC14 EVO 4 canali + T°C',
  mco14_3SezCl2InterfaceName: 'MCO14 EVO 3 secciones (Cl2)',
  mco14_5SezCl2ClTClCInterfaceName: 'MCO14 EVO 5 secciones (Cl2 + ClT + ClC)',
  nsp161InterfaceName: 'NOVA NSP161',
  nsp161CMinInterfaceName: 'NOVA NSP161 c/min',
  nsp161MAInterfaceName: 'NOVA NSP161 mA',
  nsp162PHRxInterfaceName: 'NOVA NSP162 pH/RX',
  nsp163ACle11_12InterfaceName: 'NOVA NSP163A CLE11/12',
  nsp163BCle16InterfaceName: 'NOVA NSP163B CLE16',
  pHRxTcInterfaceName: 'EF315 pH/RX/T°C',
  pHClTcInterfaceName: 'EF315 pH/CL/T°C',
  pHCacTcInterfaceName: 'EF315 pH/CAC/T°C',
  channels: 'Canales',
  sectionMin: 'Sección',
  loggedInAs: 'Autenticado como',
  badRangeInput: 'Valor datos de rango no respectados',
  open: 'Abrir',
  all: 'Todas',
  moduleComunicationTimeoutErrDesc: 'Comprobar el cableado o la alimentación de la interfaz',
  updateParamFailed: 'Actualización del parámetro fallida',
  saveChangesQuestion: '¿Está seguro de que desea guardar los cambios realizados?',
  secondaryParameters: 'Parámetros secundarios',
  P03MC14ParamDescOption1: 'Umbral a alcanzar relé K1',
  P03MC14ParamDescOption2: 'Valor central del umbral de alarma relé K1',
  P03MC14ParamDescOption3: 'Hora primera activación del relé K1',
  P04MC14ParamDescOption1: 'Histéresis relé K1',
  P04MC14ParamDescOption2: 'Histéresis por encima y por debajo del umbral relé K1',
  P04MC14ParamDescOption3: 'Hora primera desactivación del relé K1',
  P11MC14ParamDescOption1: 'Umbral a alcanzar relé K2',
  P11MC14ParamDescOption2: 'Valor central del umbral de alarma relé K2',
  P11MC14ParamDescOption3: 'Hora primera activación del relé K2',
  P12MC14ParamDescOption1: 'Histéresis relé K2',
  P12MC14ParamDescOption2: 'Histéresis por encima y por debajo del umbral relé K2',
  P12MC14ParamDescOption3: 'Hora primera desactivación del relé K2',
  P27MC14ParamDescOption1: 'Umbral a alcanzar relé K4',
  P27MC14ParamDescOption2: 'Valor central del umbral de alarma relé K4',
  P27MC14ParamDescOption3: 'Hora primera activación del relé K4',
  P28MC14ParamDescOption1: 'Histéresis relé K4',
  P28MC14ParamDescOption2: 'Histéresis por encima y por debajo del umbral relé K4',
  P28MC14ParamDescOption3: 'Hora primera desactivación del relé K4',
  alarm_lvl: 'Nivel',
  alarm_extcons: 'Habilitación Ext.Cons.',
  alarm_stop: 'Stop',
  alarm_urOr: 'UR/OR',
  alarm_timer: 'Timer',
  alarm_maintenance: 'Mantenimiento',
  alarm_autoadesco: 'Auto cebado',
  alarm_internalTemp: 'Temperatura interna',
  alarm_maxDoseTime: 'Máx tiempo dosificacón',
  alarm_maxAccPulses: 'Máx pulsos acumulables',
  alarm_sampleWater: 'Agua de muestra',
  alarm_clockKO: 'Reloj KO',
  output_magnetStatus: 'Estado imán',
  output_relayAlarm: 'Relé de Estado',
  input_lvl: 'Nivel',
  input_extcons: 'Habilitación Ext.Cons.',
  P01NSPParamDesc: 'Idioma',
  P02NSPParamDesc: '',
  P03NSPParamDesc: '',
  P04NSPParamDesc: '',
  P05NSPParamDesc: '',
  P06NSPParamDesc: '',
  P07NSPParamDesc: 'Frecuencia máxima',
  P08NSPParamDesc: '',
  P09NSPParamDesc: '',
  P10NSPParamDesc: '',
  P11NSPParamDesc: 'Setpoint',
  P12NSPParamDesc: 'Histéresis / Banda proporcional',
  P13NSPParamDesc: '',
  P14NSPParamDesc: '',
  P15NSPParamDesc: '',
  P16NSPParamDesc: '',
  P17NSPParamDesc: '',
  P18NSPParamDesc: '',
  P19NSPParamDesc: '',
  P20NSPParamDesc: '',
  P21NSPParamDesc: '',
  P22NSPParamDesc: '',
  P23NSPParamDesc: '',
  P24NSPParamDesc: '',
  P25NSPParamDesc: '',
  P26NSPParamDesc: '',
  P27NSPParamDesc: '',
  P28NSPParamDesc: '',
  P29NSPParamDesc: '',
  P30NSPParamDesc: '',
  P31NSPParamDesc: '',
  P32NSPParamDesc: '',
  P33NSPParamDesc: '',
  P34NSPParamDesc: '',
  P35NSPParamDesc: '',
  P36NSPParamDesc: '',
  P37NSPParamDesc: '',
  P38NSPParamDesc: '',
  P39NSPParamDesc: '',
  P40NSPParamDesc: '',
  P41NSPParamDesc: '',
  P42NSPParamDesc: '',
  P43NSPParamDesc: '',
  P44NSPParamDesc: '',
  P45NSPParamDesc: '',
  P46NSPParamDesc: '',
  P47NSPParamDesc: '',
  P48NSPParamDesc: '',
  P49NSPParamDesc: '',
  P50NSPParamDesc: '',
  P51NSPParamDesc: '',
  P52NSPParamDesc: '',
  P53NSPParamDesc: '',
  P54NSPParamDesc: '',
  P55NSPParamDesc: '',
  P56NSPParamDesc: '',
  P57NSPParamDesc: '',
  P58NSPParamDesc: '',
  P59NSPParamDesc: '',
  P60NSPParamDesc: '',
  P61NSPParamDesc: '',
  P62NSPParamDesc: '',
  P63NSPParamDesc: '',
  P64NSPParamDesc: '',
  P65NSPParamDesc: '',
  P66NSPParamDesc: '',
  P67NSPParamDesc: '',
  P68NSPParamDesc: '',
  P69NSPParamDesc: '',
  P70NSPParamDesc: '',
  P71NSPParamDesc: '',
  P72NSPParamDesc: '',
  P73NSPParamDesc: '',
  P74NSPParamDesc: '',
  P75NSPParamDesc: '',
  P76NSPParamDesc: '',
  P77NSPParamDesc: '',
  P78NSPParamDesc: '',
  P79NSPParamDesc: '',
  P80NSPParamDesc: '',
  P81NSPParamDesc: '',
  P82NSPParamDesc: '',
  P83NSPParamDesc: '',
  P84NSPParamDesc: '',
  P85NSPParamDesc: '',
  P86NSPParamDesc: '',
  P87NSPParamDesc: '',
  P88NSPParamDesc: '',
  P89NSPParamDesc: '',
  P90NSPParamDesc: '',
  P91NSPParamDesc: '',
  P92NSPParamDesc: '',
  P93NSPParamDesc: '',
  P94NSPParamDesc: '',
  P95NSPParamDesc: '',
  alarm_lvl1: 'Nivel 1',
  alarm_lvl2: 'Nivel 2',
  alarm_maxDosageTimeOut1: 'Tiempo máximo de dosificación Out 1',
  alarm_maxDosageTimeOut2: 'Tiempo máximo de dosificación Out 2',
  alarm_urOrMeasure1: 'UR/OR Medida 1',
  alarm_urOrMeasure2: 'UR/OR Medida 2',
  alarm_maintenancePump1: 'Mantenimiento de bombas 1',
  alarm_maintenancePump2: 'Mantenimiento de bombas 2',
  alarm_recalibrateChlorineProbeAfter24h: 'Vuelva a calibrar la sonda de cloro después de 24 horas',
  alarm_halvedSetpointForBadCalib: 'Setpoint reducido a la mitad',
  input_stop: 'Stop',
  input_ignitionDelay: 'Retraso puesta en marcha',
  input_pHStabilizer: 'Estabilización pH',
  input_maintenancePump1: 'Mantenimiento de bombas 1',
  input_maintenancePump2: 'Mantenimiento de bombas 2',
  input_recalibrateChlorineProbeAfter24h: 'Vuelva a calibrar la sonda de cloro después de 24 horas',
  input_halvedSetpointForBadCalib: 'Setpoint reducido a la mitad',
  P01EFParamDesc: '',
  P02EFParamDesc: '',
  P03EFParamDesc: 'Setpoint (Out 1)',
  P04EFParamDesc: 'Histéresis / Banda proporcional (Out 1)',
  P05EFParamDesc: 'Frecuencia máxima (Out 1)',
  P06EFParamDesc: '',
  P07EFParamDesc: '',
  P08EFParamDesc: '',
  P09EFParamDesc: '',
  P10EFParamDesc: 'Setpoint (Out 2)',
  P11EFParamDesc: 'Histéresis / Banda proporcional (Out 2)',
  P12EFParamDesc: 'Frecuencia máxima (Out 2)',
  P13EFParamDesc: '',
  P14EFParamDesc: '',
  P15EFParamDesc: '',
  P16EFParamDesc: '',
  P17EFParamDesc: '',
  P18EFParamDesc: '',
  P19EFParamDesc: '',
  P20EFParamDesc: '',
  P21EFParamDesc: '',
  P22EFParamDesc: '',
  P23EFParamDesc: '',
  P24EFParamDesc: '',
  P25EFParamDesc: '',
  P26EFParamDesc: '',
  P27EFParamDesc: '',
  P28EFParamDesc: '',
  P29EFParamDesc: '',
  P30EFParamDesc: '',
  P31EFParamDesc: '',
  P32EFParamDesc: '',
  P33EFParamDesc: '',
  P34EFParamDesc: '',
  eventsLog: 'LOG de eventos',
  events: 'Eventos',
  alarm_new_stop: 'Strumento OFF',
  alarm_new_inputCadOff: 'Ingresso CAD (OFF)',
  alarm_new_flow: 'Allarme Flow',
  alarm_new_urOr: 'Allarme UR/OR',
  alarm_new_lowPHLvl: 'Allarme Min livello pH',
  alarm_new_lowCl2Lvl: 'Allarme Min livello Cl2',
  alarm_new_maxPHDosage: 'Allarme Max dosaggio pH',
  alarm_new_maxCl2Dosage: 'Allarme Max dosaggio Cl2',
  alarm_new_pHMin: 'Avviso minimo pH',
  alarm_new_phMax: 'Avviso massimo pH',
  alarm_new_cl2Min: 'Avviso minimo Cl2',
  alarm_new_cl2Max: 'Avviso massimo Cl2',
  alarm_new_clock: 'Timer',
  alarm_new_maxTimeK1: 'Allarme Max TK1',
  alarm_new_maxTimeK2: 'Allarme Max TK2',
  alarm_new_maxTimeK4: 'Allarme Max TK4',
  alarm_new_maxTimeK5: 'Allarme Max TK5',
  alarm_new_redox: 'Allarme Redox',
  alarm_new_zeroChlorine: 'Allarme 0 Cl2',
  output_mixer2: 'Mixer 2',
  alarm_extConsensus: 'Consenso esterno',
  alarm_lvl1MinAlarm: 'Allarme Min livello 1',
  alarm_lvl2MinAlarm: 'Allarme Min livello 2',
  alarm_lvl3MinAlarm: 'Allarme Min livello 3',
  alarm_off: 'Off',
  alarm_new_maintenance: 'Rich. Manutenzione',
  alarm_new_timer: 'Timer control',
  alarm_new_lvl: 'Allarme Livello',
  alarm_new_extcons: 'Allarme Ext.Cons',
  alarm_new_internalTemp: 'Allarme temperatura interna',
  alarm_new_clockKO: 'Allarme Orologio KO',
  alarm_new_sampleWater: 'Sample water',
  alarm_new_maxDoseTime: 'Allarme Max Dosaggio',
  alarm_new_maintenancePump1: 'Manutenzione P1',
  alarm_new_maintenancePump2: 'Manutenzione P2',
  alarm_new_urOrMeasure1: 'Allarme UR/OR Misura 1',
  alarm_new_urOrMeasure2: 'Allarme UR/OR Misura 2',
  alarm_new_lvl1: 'Allarme Livello 1',
  alarm_new_lvl2: 'Allarme Livello 2',
  alarm_new_maxDosageTimeOut1: 'Allarme MAX T P1',
  alarm_new_maxDosageTimeOut2: 'Allarme MAX T P2',
  statuses: 'Estados',
  theme: 'tema',
  toolStatus: 'Estado de herramienta',
  minWarningLimit: 'Valor ideal mín',
  maxWarningLimit: 'Valor ideal máx',
  canNotSaveOfflineOrErrModule:
    'la configuración no se puede guardar cuando el módulo está fuera de línea o tiene un error de comunicación.',
  ew2PoolInterfaceName: 'Evolution EW² Pool',
  ew2Pool_pHOrpTcInterfaceName: 'EW² Pool pH/ORP/T',
  ew2Pool_pHCacTcInterfaceName: 'EW² Pool pH/CAC/T',
  ew2Pool_pHCleTcInterfaceName: 'EW² Pool pH/CLE/T',
  nova2Pool_pHOrpTcInterfaceName: 'Nova² Pool pH/ORP/T',
  nova2Pool_pHCacTcInterfaceName: 'Nova² Pool pH/CAC/T',
  nova2Pool_pHCleTcInterfaceName: 'Nova² Pool pH/CLE/T',
  alarm_minThresholdM1: 'E01 soglia minima M1',
  alarm_maxThresholdM1: 'E02 soglia massima M1',
  alarm_lvl1m1: 'E03 level 1 M1',
  alarm_lvl2m1: 'E04 level 2 M1',
  alarm_underRangeM1: 'E05 under range M1',
  alarm_overRangeM1: 'E06 over range M1',
  alarm_maxDosageM1: 'E07 massimo dosaggio M1',
  alarm_minThresholdM2: 'E09 soglia minima M2',
  alarm_maxThresholdM2: 'E10 soglia massima M2',
  alarm_lvl1m2: 'E11 level 1 M2',
  alarm_lvl2m2: 'E12 level 2 M2',
  alarm_underRangeM2: 'E13 under range M2',
  alarm_overRangeM2: 'E14 over range M2',
  alarm_maxDosageM2: 'E15 massimo dosaggio M2',
  alarm_sampleFlow: 'E18 flusso campione',
  alarm_pHPriority: 'E19 pH priority',
  output_pfmM1: 'DO1 - PFM M1',
  output_pfmM2: 'DO2 - PFM M2',
  output_outM1: 'DO10 - OUT M1',
  output_flocculant: 'DO7 - FLOCCULANTE',
  output_outM2: 'DO11 - OUT M2',
  output_antiAlgae: 'DO8 - ANTIALGA',
  output_poolRelayAlarm: 'DO9 - RELE’ ALLARME',
  input_lvl1m1: 'DI1 - Livello 1 M1',
  input_lvl2m1: 'DI2 - Livello 2 M1',
  input_externalConsent: 'DI3 - Consenso esterno',
  input_ecoMode: 'DI4 - Eco mode',
  input_sampleWater: 'DI6 - Samplewater',
  input_lvl1m2: 'DI8 - Livello 1 M2',
  input_lvl2m2: 'DI9 - Livello 2 M2',
  setpointM1OnOffParamDesc: 'Setpoint M1 On/Off',
  propBandM1OnOffParamDesc: 'Isteresi M1 On/Off',
  dosageDirM1OnOffParamDesc: 'Direzione Dosaggio M1 On/Off',
  maxDosageTimeM1OnOffParamDesc: 'Tempo MAX Dosaggio M1 On/Off',
  setpointM1PwmParamDesc: 'Setpoint M1 PWM',
  propBandM1PwmParamDesc: 'Banda Proporzionale M1 PWM',
  dosageDirM1PwmParamDesc: 'Direzione Dosaggio M1 PWM',
  maxDosageTimeM1PwmParamDesc: 'Tempo MAX Dosaggio M1 PWM',
  setpointM2OnOffParamDesc: 'Setpoint M2 On/Off',
  setpointM2PwmParamDesc: 'Setpoint M2 PWM',
  setpointM1PfmParamDesc: 'Setpoint M1 PFM',
  propBandM1PfmParamDesc: 'Banda Proporzionale M1 PFM',
  dosageDirM1PfmParamDesc: 'Direzione Dosaggio M1 PFM',
  maxDosageTimeM1PfmParamDesc: 'Tempo MAX Dosaggio M1 PFM',
  minFreqM1ParamDesc: 'Frequenza minima M1',
  maxFreqM1ParamDesc: 'Frequenza massima M1',
  minLimitM1ParamDesc: 'Limite MIN M1',
  maxLimitM1ParamDesc: 'Limite MAX M1',
  propBandM2OnOffParamDesc: 'Isteresi M2 On/Off',
  dosageDirM2OnOffParamDesc: 'Direzione Dosaggio M2 On/Off',
  maxDosageTimeM2OnOffParamDesc: 'Tempo MAX Dosaggio M2 On/Off',
  propBandM2PwmParamDesc: 'Banda Proporzionale M2 PWM',
  dosageDirM2PwmParamDesc: 'Direzione Dosaggio M2 PWM',
  maxDosageTimeM2PwmParamDesc: 'Tempo MAX Dosaggio M2 PWM',
  setpointM2PfmParamDesc: 'Setpoint M2 PFM',
  propBandM2PfmParamDesc: 'Banda Proporzionale M2 PFM',
  dosageDirM2PfmParamDesc: 'Direzione Dosaggio M2 PFM',
  maxDosageTimeM2PfmParamDesc: 'Tempo MAX Dosaggio M2 PFM',
  minFreqM2ParamDesc: 'Frequenza minima M2',
  maxFreqM2ParamDesc: 'Frequenza massima M2',
  setpointEcoModeParamDesc: 'Setpoint Eco/Boost mode',
  udmTempParamDesc: 'UDM Temperature',
  maxLimitM2ParamDesc: 'Limite MAX M2',
  minLimitM2ParamDesc: 'Limite MIN M2',
  acidification: 'acidificación',
  alkalization: 'alcalinización',
  dechlorination: 'descloración',
  chlorination: 'cloración',
  output_out230VM2: 'DO6 - OUT_230V M2',
  output_out230VM1: 'DO5 - OUT_230V M1',
  output_phMagnet: 'stato magnete pH',
  output_clMagnet: 'stato magnete Cl',
};
