export const it = {
  no: 'No',
  yes: 'Sì',
  userNotFound: 'Utente non trovato',
  invalidCredentials: 'Credenziali non valide',
  badOldPassword: 'Vecchia password errata',
  somethingWentWrong: 'Qualcosa è andato storto',
  sww: 'Qualcosa è andato storto',
  tooReqError: 'Troppe richieste, riprovare più tardi',
  fieldRequired: 'Campo richiesto',
  invalidEmail: 'E-mail non valida',
  passwordTooShort: 'La password deve contenere almeno {0} caratteri',
  login: 'Login',
  signIn: 'Accedi',
  email: 'E-mail',
  password: 'Password',
  dashboard: 'Pannello di controllo',
  users: 'Utenti',
  customers: 'Clienti',
  managers: 'Gestori',
  totalDevices: 'Totale dispositivi',
  onlineDevices: 'Dispositivi online',
  devicesOnAlarm: 'Dispositivi in allarme',
  deviceStatus: 'Stato Installazioni',
  search: 'Cerca',
  mac: 'Mac',
  model: 'Modello',
  registrationNumber: 'Matricola',
  alarms: 'Allarmi',
  warnings: 'Avvisi',
  status: 'Stato',
  lastConnection: 'Ultima connessione',
  details: 'Dettagli',
  summary: 'Riepilogo',
  viewAll: 'Visualizza tutto',
  customersList: 'Lista clienti',
  managerList: 'Lista gestori',
  id: 'ID',
  nameSurname: 'Nome e cognome',
  city: 'Città',
  role: 'Ruolo',
  notAuthorized: 'Non autorizzato',
  confirm: 'Conferma',
  disconnectQuestion: 'Eseguire il logout?',
  logout: 'logout',
  menu: 'Menu',
  online: 'on-line',
  offline: 'off-line',
  actives: 'Attivi',
  user: 'Utente',
  admin: 'Amministratore',
  technician: 'Tecnico',
  alarmsHistory: 'Storico allarmi',
  date: 'Data',
  dates: 'Date',
  name: 'nome',
  type: 'tipo',
  alarm: 'Allarme',
  warning: 'Avviso',
  forgetPasswordQuestion: 'Password dimenticata?',
  signUp: 'Registrati',
  registration: 'Registrazione',
  attention: 'Attenzione',
  ok: 'OK',
  acceptDataPrivacyAgreementWarn: 'È necessario acconsentire al trattamento dei dati',
  acceptDataMarketingAgreementWarn:
    "Selezionare un'opzione per l’utilizzo dei dati a scopo di marketing",
  passwordMismatch: 'Le password non corrispondono',
  repeatPassword: 'Ripeti password',
  telephone: 'Telefono',
  nation: 'Paese',
  company: 'Azienda',
  dataAgreement:
    'Selezionando la casella accetti le condizioni per il trattamento dei dati personali secondo la direttiva UE 679/2016 e dichiari di essere maggiorenne',
  agree: 'Accetto',
  notAgree: 'Non accetto',
  marketingAgreement:
    'Selezionando la casella accetti l’uso dei tuoi dati personali da parte di STEIEL per scopi di marketing',
  privacyTreatment: 'Trattamento dati personali',
  verifyEmail: 'Verifica e-mail',
  emailSentToYourEmail: "Abbiamo inviato un'e-mail di verifica all’indirizzo:",
  emailNotReceivedQuestion: 'Non hai ricevuto l’e-mail?',
  sendAgain: 'Invia di nuovo',
  verificationEmailSent: 'E-mail di verifica inviata',
  lostPasswordQuestion: 'Hai dimenticato la password?',
  lostPasswordInstructions:
    'Inserisci l’indirizzo e-mail e ti manderemo le istruzioni per impostare una nuova password',
  resetPassword: 'Reset password',
  goBack: 'Torna indietro',
  emailSentToYourAddress: "Abbiamo inviato un'e-mail al tuo indirizzo",
  passwordChanged: 'Password modificata',
  notice: 'Avviso',
  changePassword: 'Cambia password',
  settings: 'Impostazioni',
  add: 'Aggiungi',
  profile: 'Profilo',
  security: 'Sicurezza',
  saveChanges: 'Salva modifiche',
  profileSavedSuccessfully: 'Profilo salvato con successo',
  dataSavedSuccessfully: 'Dati salvati con successo',
  oldPassword: 'Vecchia password',
  passwordChangedSuccessfully: 'Password cambiata con successo',
  counterResettedSuccessfully: 'Contatore resettato con successo',
  at: 'alle',
  noDataFound: 'Nessun dato trovato',
  deviceManagement: 'Amministrazione dispositivi',
  usersManagement: 'Amministrazione utenti',
  version: 'Versione',
  dateAndTime: 'Data/Ora',
  place: 'Luogo',
  comeback: 'Torna indietro',
  setup: 'Setup',
  default: 'Default',
  interface: 'Interfaccia',
  temperature: 'Temperatura',
  ph: 'pH',
  chlorine: 'Cloro',
  totalChlorine: 'Cloro totale',
  freeChlorine: 'Cloro libero',
  combinedChlorine: 'Cloro combinato',
  table: 'Tabella',
  charts: 'Grafici',
  setpoint: 'Setpoint',
  customizeMeasurements: 'Personalizza layout',
  active: 'Attivo',
  deactivated: 'Disattivo',
  minAcceptableValue: 'Valore min accettabile',
  maxAcceptableValue: 'Valore max accettabile',
  outputs: 'Uscite',
  inputs: 'Ingressi',
  on: 'on',
  off: 'off',
  value: 'Valore',
  exportTable: 'Esporta tabella',
  exportAlarms: 'Esporta allarmi',
  localDateTime: 'Ora/Data (locale)',
  notes: 'Note',
  channel: 'Canale',
  description: 'Descrizione',
  deviceDetails: 'Dettagli dispositivo',
  measure: 'Misura',
  enable: 'Abilita',
  unitOfMeasure: 'Unità di misura',
  scaleStart: 'Inizio scala',
  bottomScale: 'Fondo scala',
  minAlarm: 'Allarme min',
  maxAlarm: 'Allarme max',
  minAcceptableLimit: 'Limite accettabile min',
  maxAcceptableLimit: 'Limite accettabile max',
  decimals: 'Decimali',
  selectWidget: 'Seleziona Widget',
  notifications: 'Notifiche',
  notificationsDelay: 'Ritardo notifiche (minuti)',
  recipientList: 'Elenco destinatari',
  customizeNotification: 'Personalizza notifica',
  apply: 'Applica',
  view: 'Visualizza',
  searchPlaceholder: 'Ricerca ID, nome, luogo',
  filter: 'Filtro',
  withAlarm: 'In allarme',
  notWithAlarm: 'Non in allarme',
  cancel: 'Annulla',
  deviceDefaultSettingsResetQuestion:
    'Desideri riportare il dispositivo alle impostazioni predefinite?',
  samplingTime: 'Tempo di campionamento (min)',
  timeZone: 'Fuso orario',
  macAddress: 'Indirizzo mac',
  tool: 'Strumento',
  newCustomer: 'Nuovo cliente',
  newManager: 'Nuovo gestore',
  locality: 'Località',
  notificationManagement: 'Gestione notifiche',
  manageNotifications: 'Gestisci notifiche',
  sendEmail: 'Invia e-mail',
  usersList: 'Elenco utenti',
  modifyCustomer: 'Modifica cliente',
  modifyManager: 'Modifica gestore',
  newUser: 'Nuovo utente',
  deleteUser: 'Elimina utente',
  notePlaceholder: 'Inserisci nota',
  insertLogo: 'Inserisci logo',
  language: 'Lingua',
  file: 'File',
  create: 'Crea',
  modify: 'Modifica',
  update: 'Aggiorna',
  createNewCustomer: 'Crea nuovo cliente',
  createNewManager: 'Crea nuovo gestore',
  viewer: 'Visualizzatore',
  superTechnician: 'Amministratore n.2',
  supertechnician: 'Amministratore n.2',
  newDevice: 'Nuovo dispositivo',
  createNewDevice: 'Crea nuovo dispositivo',
  installer: 'Installatore',
  newModule: 'Nuovo modulo',
  modifyDevice: 'Modifica dispositivo',
  searchByNamePlace: 'Ricerca nome, località',
  searchByNameSurnameUsername: 'Ricerca nome, cognome, username ...',
  surname: 'Cognome',
  username: 'Username',
  modifyUser: 'Modifica utente',
  confirmPassword: 'Conferma password',
  insertNote: 'Inserisci nota',
  createNewUser: 'Crea nuovo utente',
  customersManagement: 'Amministrazione clienti',
  customer: 'Cliente',
  manager: 'Gestore',
  send: 'Invia',
  modifyPassword: 'Modifica password',
  newPassword: 'Nuova password',
  passwordRecoveryInstructions:
    'Inserisci la tua e-mail, ti invieremo un link per modificare la password',
  userProfile: 'Profilo utente',
  italian: 'Italiano',
  english: 'Inglese',
  currentPassword: 'Password corrente',
  new: 'Nuovo',
  deviceInterfaceChartTitle: 'SwimEx Cold Plunge Pool',
  deviceInterfaceChartMobileTip:
    'Orienta il dispositivo in orizzontale per una migliore visualizzazione del grafico',
  deviceInterfaceChartTip: 'Posiziona il puntatore sul grafico per visualizzare i dati',
  dtBodyNoMatch: 'Nessun dato trovato',
  dtBodyTooltip: 'Ordina',
  dtBodyColumnHeaderTooltip: 'Ordina per',
  dtNextPage: 'Pagina successiva',
  dtPreviousPage: 'Pagina precedente',
  dtRowsPerPage: 'Righe per pagina:',
  jumpToPage: 'Salta a pagina:',
  dtDisplayRows: 'di',
  dtToolbarSearch: 'Cerca',
  dtToolbarDownloadCsv: 'Scarica CSV',
  dtToolbarPrint: 'Stampa',
  dtToolbarViewColumns: 'Vista colonne',
  dtToolbarFilterTable: 'Filtra tabella',
  dtFilterAll: 'Tutto',
  dtFilterTitle: 'Filtri',
  dtFilterReset: 'Reset',
  dtViewColumnsTitle: 'Mostra colonne',
  dtViewColumnsTitleAria: 'Mostra/nascondi colonne',
  dtSelectedRowsText: 'Righe selezionate',
  dtSelectedRowsDelete: 'Elimina',
  dtSelectedRowsDeleteAria: 'Elimina righe selezionate',
  success: 'Riuscito',
  recoveryPasswordEmailSent: 'E-mail di recupero password inviata',
  french: 'Francese',
  noInterfaceConnected: 'Nessuna interfaccia connessa',
  hthInterfaceName: 'CYCL’EAU First',
  hthProInterfaceName: 'CYCL’EAU Pro',
  input_pHLvl: 'Livello pH',
  input_cl2Lvl: 'Livello cloro',
  input_lvl3: 'Livello 3',
  input_flow: 'Flow',
  input_cadOff: 'CAD (Off)',
  output_out1: 'Out 1',
  output_out2: 'Out 2',
  output_alarm: 'Relè allarme',
  alarm_lowPHLvl: 'Livello pH basso',
  alarm_lowCl2Lvl: 'Livello cloro basso',
  alarm_flow: 'Flow',
  alarm_onOffKey: 'Tasto ON/OFF',
  alarm_inputCadOff: 'Ingresso CAD Off',
  alarm_maxPHDosage: 'Massimo dosaggio pH',
  alarm_maxCl2Dosage: 'Massimo dosaggio cloro',
  alarm_startDelay: 'Avvio ritardato',
  alarm_pHMin: 'pH minimo',
  alarm_phMax: 'pH massimo',
  alarm_cl2Min: 'Cl2 minimo',
  alarm_cl2Max: 'Cl2 massimo',
  noMeasurementFound: 'Nessuna misurazione trovata',
  settingsSavedSuccessfully: 'Impostazioni salvate con successo',
  parameters: 'Parametri',
  parameter: 'Parametro',
  noParametersFound: 'Nessun parametro trovato',
  setpointPHParamDesc: 'Setpoint 1 (OUT_pH)',
  setpointCl2ParamDesc: 'Setpoint 2 (OUT_Cl2)',
  minPHAlarmParamDesc: 'Allarme (avviso) min. pH',
  maxPHAlarmParamDesc: 'Allarme (avviso) max. pH',
  minFreeChlorineParamDesc: 'Allarme (avviso) min. cloro',
  maxFreeChlorineParamDesc: 'Allarme (avviso) max. cloro',
  maxCombChlorineParamDesc: 'Allarme (avviso) max. cloro combinato',
  settingTypePHParamDesc: 'Tipo di regolazione OUT_pH',
  settingTypeCl2ParamDesc: 'Tipo di regolazione OUT_Cl2',
  hysteresisPHParamDesc: 'Isteresi OUT_pH',
  hysteresisCl2ParamDesc: 'Isteresi OUT_Cl2',
  sensePHParamDesc: 'Direzione pH',
  startDelayParamDesc: 'Ritardo avvio (sec)',
  flowDelayParamDesc: 'Ritardo dopo ripristino flusso (sec)',
  maxInjectionTimeParamDesc: 'Tempo di iniezione max. (min)',
  pHTankLvlParamDesc: 'Livello serbatoio pH',
  chlorineTankLvlParamDesc: 'Livello serbatoio cloro',
  flowSensorParamDesc: 'Sensore di flusso',
  totalChlorineParamDesc: 'Cloro totale',
  languageParamDesc: 'Lingua',
  alarmRelayParamDesc: 'Relè allarme',
  serialLineParamDesc: 'Linea seriale',
  serialLineCommSpeedParamDesc: 'Velocità di comunicazione della linea seriale',
  modbusCommAddrParamDesc: 'Indirizzo di comunicazione Modbus',
  passwordParamDesc: 'Password',
  proportional: 'Proporzionale',
  onOff: 'On-off',
  alkaline: 'Alcalino',
  acid: 'Acido',
  spanish: 'Spagnolo',
  ascii: 'Ascii',
  modbus: 'Modbus',
  min: 'min.',
  max: 'max.',
  onlineWithAlarm: 'On-line con allarme',
  macAddressNotFound: 'Indirizzo mac non trovato',
  invalidCompanyValue: 'Valore azienda non valido',
  deleteDevice: 'Elimina dispositivo',
  deleteDeviceQuestion: 'Eliminare il dispositivo?',
  userCreatedSuccessfully: 'Utente creato con successo',
  deleteUserQuestion: "Eliminare l'utente {0}?",
  deviceCreatedSuccessfully: 'Dispositivo creato con successo',
  noMeasurementsFoundOrEnabled: 'Nessuna misura abilitata o trovata',
  companyDomain: 'Dominio azienda',
  from: 'da',
  to: 'a',
  alarmsFixed: 'Allarmi risolti',
  badInputData: 'Dati di input errati',
  changesCompleted: 'Modifiche completate',
  upload: 'Carica',
  invalidFileSize: 'Dimensione file non valida',
  invalidFileType: 'Tipo file non valido',
  alarm_maxTimeK1: 'Tempo massimo K1',
  alarm_maxTimeK2: 'Tempo massimo K2',
  alarm_maxTimeK4: 'Tempo massimo K4',
  alarm_maxTimeK5: 'Tempo massimo K5',
  alarm_ignitionDelay: 'Ritardo accensione',
  alarm_pHStabilizer: 'Stabilizzazione pH',
  alarm_clock: 'Orologio',
  alarm_zeroChlorine: 'Zero cloro',
  alarm_redox: 'Redox',
  alarm_superchlorination: 'Superclorazione',
  alarm_notUsed1: 'Non usato 1',
  alarm_notUsed2: 'Non usato 2',
  alarm_notUsed3: 'Non usato 3',
  alarm_notUsed4: 'Non usato 4',
  alarm_richAssist: 'Richiesta assistenza',
  alarm_toolBlock: 'Blocco strumento',
  output_k1: 'K1',
  output_k2: 'K2',
  output_k3: 'K3',
  output_k4: 'K4',
  output_k5: 'K5',
  output_ev: 'EV',
  output_perist1: 'Perist1',
  output_perist2: 'Perist2',
  output_perist3: 'Perist3',
  output_mixer: 'Mixer 1',
  input_lvl1: 'Livello 1',
  input_lvl2: 'Livello 2',
  input_off: 'Ingresso OFF',
  input_imp: 'Ingresso IMP',
  P01MC14ParamDesc: "Misura associata all'uscita relè K1",
  P02MC14ParamDesc: 'Tipo uscita relè K1',
  P03MC14ParamDesc: '',
  P04MC14ParamDesc: '',
  P05MC14ParamDesc: '',
  P06MC14ParamDesc: '',
  P07MC14ParamDesc: 'Allarme massimo tempo dosaggio relè K1 (ore : min)',
  P08MC14ParamDesc: 'Condizioni OFF relè K1',
  P09MC14ParamDesc: "Misura associata all'uscita relè K2",
  P10MC14ParamDesc: 'Tipo uscita relè K2',
  P11MC14ParamDesc: '',
  P12MC14ParamDesc: '',
  P13MC14ParamDesc: '',
  P14MC14ParamDesc: '',
  P15MC14ParamDesc: 'Allarme massimo tempo dosaggio relè K2 (ore : min)',
  P16MC14ParamDesc: 'Condizioni OFF relè K2',
  P17MC14ParamDesc: '',
  P18MC14ParamDesc: '',
  P19MC14ParamDesc: '',
  P20MC14ParamDesc: '',
  P21MC14ParamDesc: '',
  P22MC14ParamDesc: '',
  P23MC14ParamDesc: '',
  P24MC14ParamDesc: '',
  P25MC14ParamDesc: '',
  P26MC14ParamDesc: '',
  P27MC14ParamDesc: '',
  P28MC14ParamDesc: '',
  P29MC14ParamDesc: '',
  P30MC14ParamDesc: '',
  P31MC14ParamDesc: '',
  P32MC14ParamDesc: '',
  P33MC14ParamDesc: '',
  P34MC14ParamDesc: '',
  P35MC14ParamDesc: '',
  P36MC14ParamDesc: '',
  P37MC14ParamDesc: '',
  P38MC14ParamDesc: '',
  P39MC14ParamDesc: '',
  P40MC14ParamDesc: '',
  P41MC14ParamDesc: '',
  P42MC14ParamDesc: '',
  P43MC14ParamDesc: '',
  P44MC14ParamDesc: '',
  P45MC14ParamDesc: '',
  P46MC14ParamDesc: '',
  P47MC14ParamDesc: '',
  P48MC14ParamDesc: '',
  P49MC14ParamDesc: '',
  P50MC14ParamDesc: '',
  P51MC14ParamDesc: '',
  P52MC14ParamDesc: '',
  P53MC14ParamDesc: '',
  P54MC14ParamDesc: '',
  P55MC14ParamDesc: '',
  P56MC14ParamDesc: '',
  P57MC14ParamDesc: '',
  P58MC14ParamDesc: '',
  P59MC14ParamDesc: '',
  P60MC14ParamDesc: '',
  P61MC14ParamDesc: '',
  P62MC14ParamDesc: '',
  P63MC14ParamDesc: '',
  P64MC14ParamDesc: '',
  P65MC14ParamDesc: '',
  P66MC14ParamDesc: '',
  P67MC14ParamDesc: '',
  P68MC14ParamDesc: '',
  P69MC14ParamDesc: '',
  P70MC14ParamDesc: '',
  P71MC14ParamDesc: '',
  P72MC14ParamDesc: '',
  P73MC14ParamDesc: '',
  P74MC14ParamDesc: '',
  P75MC14ParamDesc: '',
  P76MC14ParamDesc: '',
  P77MC14ParamDesc: '',
  P78MC14ParamDesc: '',
  P79MC14ParamDesc: '',
  P80MC14ParamDesc: '',
  P81MC14ParamDesc: '',
  P82MC14ParamDesc: '',
  P83MC14ParamDesc: '',
  P84MC14ParamDesc: '',
  P85MC14ParamDesc: '',
  P86MC14ParamDesc: '',
  P87MC14ParamDesc: '',
  P88MC14ParamDesc: '',
  P89MC14ParamDesc: '',
  P90MC14ParamDesc: '',
  P91MC14ParamDesc: '',
  P92MC14ParamDesc: '',
  P93MC14ParamDesc: '',
  P94MC14ParamDesc: '',
  notAvailable: 'Non disponibile',
  disabled: 'Disabilitata',
  closingOverthresholds: 'Chiusura al superamento soglie',
  openingOverthreshold: 'Apertura al superamento soglie',
  upward: 'In salita',
  downward: 'In discesa',
  hourlyOperation: 'Funzionamento orario',
  unknown: 'Sconosciuto',
  mc14evoPHRxCle12TcInterfaceName: 'MC14 EVO pH/RX/CLE12/T',
  mc14evoPHRxCacTcInterfaceName: 'MC14 EVO pH/RX/CAC/T',
  mc14evo_4CanaliTcInterfaceName: 'MC14 EVO 4 canali + T',
  mco14_3SezCl2InterfaceName: 'MCO14 EVO 3 sez. (Cl2)',
  mco14_5SezCl2ClTClCInterfaceName: 'MCO14 EVO 5 sez. (Cl2 + ClT + ClC)',
  nsp161InterfaceName: 'NOVA NSP161',
  nsp161CMinInterfaceName: 'NOVA NSP161 c/min',
  nsp161MAInterfaceName: 'NOVA NSP161 mA',
  nsp162PHRxInterfaceName: 'NOVA NSP162 pH/RX',
  nsp163ACle11_12InterfaceName: 'NOVA NSP163A CLE11/12',
  nsp163BCle16InterfaceName: 'NOVA NSP163B CLE16',
  pHRxTcInterfaceName: 'EF315 pH/RX/T',
  pHClTcInterfaceName: 'EF315 pH/CL/T',
  pHCacTcInterfaceName: 'EF315 pH/CAC/T',
  channels: 'Canali',
  sectionMin: 'Sezione',
  loggedInAs: 'Autenticato come',
  badRangeInput: 'Valore dati intervallo non rispettati',
  open: 'Apri',
  all: 'Tutte',
  moduleComunicationTimeoutErrDesc: 'Controlla il cablaggio o l’alimentazione dell’interfaccia',
  updateParamFailed: 'Aggiornamento parametro fallito',
  saveChangesQuestion: 'Sei sicuro di voler salvare le modifiche?',
  secondaryParameters: 'Parametri secondari',
  P03MC14ParamDescOption1: 'Soglia da raggiungere relè K1',
  P03MC14ParamDescOption2: 'Valore centrale soglia allarme relè K1',
  P03MC14ParamDescOption3: 'Orario prima attivazione relè K1',
  P04MC14ParamDescOption1: 'Isteresi relè K1',
  P04MC14ParamDescOption2: 'Isteresi sopra e sotto alla soglia relè K1',
  P04MC14ParamDescOption3: 'Orario prima disattivazione relè K1',
  P11MC14ParamDescOption1: 'Soglia da raggiungere relè K2',
  P11MC14ParamDescOption2: 'Valore centrale soglia allarme relè K2',
  P11MC14ParamDescOption3: 'Orario prima attivazione relè K2',
  P12MC14ParamDescOption1: 'Isteresi relè K2',
  P12MC14ParamDescOption2: 'Isteresi sopra e sotto alla soglia relè K2',
  P12MC14ParamDescOption3: 'Orario prima disattivazione relè K2',
  P27MC14ParamDescOption1: 'Soglia da raggiungere relè K4',
  P27MC14ParamDescOption2: 'Valore centrale soglia allarme relè K4',
  P27MC14ParamDescOption3: 'Orario prima attivazione relè K4',
  P28MC14ParamDescOption1: 'Isteresi relè K4',
  P28MC14ParamDescOption2: 'Isteresi sopra e sotto alla soglia relè K4',
  P28MC14ParamDescOption3: 'Orario prima disattivazione relè K4',
  alarm_lvl: 'Livello',
  alarm_extcons: 'Abilitazione Ext.Cons.',
  alarm_stop: 'Stop',
  alarm_urOr: 'UR/OR',
  alarm_timer: 'Timer',
  alarm_maintenance: 'Manutenzione',
  alarm_autoadesco: 'Auto adesco',
  alarm_internalTemp: 'Temperatura interna',
  alarm_maxDoseTime: 'Max tempo dosaggio',
  alarm_maxAccPulses: 'Max impulsi accumulabili',
  alarm_sampleWater: 'Acqua campione',
  alarm_clockKO: 'Orologio KO',
  output_magnetStatus: 'Stato magnete',
  output_relayAlarm: 'Relè di Stato',
  input_lvl: 'Livello',
  input_extcons: 'Abilitazione Ext.Cons.',
  P01NSPParamDesc: 'Lingua',
  P02NSPParamDesc: '',
  P03NSPParamDesc: '',
  P04NSPParamDesc: '',
  P05NSPParamDesc: '',
  P06NSPParamDesc: '',
  P07NSPParamDesc: 'Frequenza massima',
  P08NSPParamDesc: '',
  P09NSPParamDesc: '',
  P10NSPParamDesc: '',
  P11NSPParamDesc: 'Setpoint',
  P12NSPParamDesc: 'Isteresi / Banda proporzionale',
  P13NSPParamDesc: '',
  P14NSPParamDesc: '',
  P15NSPParamDesc: '',
  P16NSPParamDesc: '',
  P17NSPParamDesc: '',
  P18NSPParamDesc: '',
  P19NSPParamDesc: '',
  P20NSPParamDesc: '',
  P21NSPParamDesc: '',
  P22NSPParamDesc: '',
  P23NSPParamDesc: '',
  P24NSPParamDesc: '',
  P25NSPParamDesc: '',
  P26NSPParamDesc: '',
  P27NSPParamDesc: '',
  P28NSPParamDesc: '',
  P29NSPParamDesc: '',
  P30NSPParamDesc: '',
  P31NSPParamDesc: '',
  P32NSPParamDesc: '',
  P33NSPParamDesc: '',
  P34NSPParamDesc: '',
  P35NSPParamDesc: '',
  P36NSPParamDesc: '',
  P37NSPParamDesc: '',
  P38NSPParamDesc: '',
  P39NSPParamDesc: '',
  P40NSPParamDesc: '',
  P41NSPParamDesc: '',
  P42NSPParamDesc: '',
  P43NSPParamDesc: '',
  P44NSPParamDesc: '',
  P45NSPParamDesc: '',
  P46NSPParamDesc: '',
  P47NSPParamDesc: '',
  P48NSPParamDesc: '',
  P49NSPParamDesc: '',
  P50NSPParamDesc: '',
  P51NSPParamDesc: '',
  P52NSPParamDesc: '',
  P53NSPParamDesc: '',
  P54NSPParamDesc: '',
  P55NSPParamDesc: '',
  P56NSPParamDesc: '',
  P57NSPParamDesc: '',
  P58NSPParamDesc: '',
  P59NSPParamDesc: '',
  P60NSPParamDesc: '',
  P61NSPParamDesc: '',
  P62NSPParamDesc: '',
  P63NSPParamDesc: '',
  P64NSPParamDesc: '',
  P65NSPParamDesc: '',
  P66NSPParamDesc: '',
  P67NSPParamDesc: '',
  P68NSPParamDesc: '',
  P69NSPParamDesc: '',
  P70NSPParamDesc: '',
  P71NSPParamDesc: '',
  P72NSPParamDesc: '',
  P73NSPParamDesc: '',
  P74NSPParamDesc: '',
  P75NSPParamDesc: '',
  P76NSPParamDesc: '',
  P77NSPParamDesc: '',
  P78NSPParamDesc: '',
  P79NSPParamDesc: '',
  P80NSPParamDesc: '',
  P81NSPParamDesc: '',
  P82NSPParamDesc: '',
  P83NSPParamDesc: '',
  P84NSPParamDesc: '',
  P85NSPParamDesc: '',
  P86NSPParamDesc: '',
  P87NSPParamDesc: '',
  P88NSPParamDesc: '',
  P89NSPParamDesc: '',
  P90NSPParamDesc: '',
  P91NSPParamDesc: '',
  P92NSPParamDesc: '',
  P93NSPParamDesc: '',
  P94NSPParamDesc: '',
  P95NSPParamDesc: '',
  alarm_lvl1: 'Livello 1',
  alarm_lvl2: 'Livello 2',
  alarm_maxDosageTimeOut1: 'Max tempo dosaggio Out 1',
  alarm_maxDosageTimeOut2: 'Max tempo dosaggio Out 2',
  alarm_urOrMeasure1: 'UR/OR Misura 1',
  alarm_urOrMeasure2: 'UR/OR Misura 2',
  alarm_maintenancePump1: 'Manutenzione Pompa 1',
  alarm_maintenancePump2: 'Manutenzione Pompa 2',
  alarm_recalibrateChlorineProbeAfter24h: 'Ricalibrare la sonda cloro dopo 24 ore',
  alarm_halvedSetpointForBadCalib: 'Setpoint dimezzato',
  input_stop: 'Stop',
  input_ignitionDelay: 'Ritardo accensione',
  input_pHStabilizer: 'Stabilizzazione pH',
  input_maintenancePump1: 'Manutenzione Pompa 1',
  input_maintenancePump2: 'Manutenzione Pompa 2',
  input_recalibrateChlorineProbeAfter24h: 'Ricalibrare la sonda cloro dopo 24 ore',
  input_halvedSetpointForBadCalib: 'Setpoint dimezzato',
  P01EFParamDesc: '',
  P02EFParamDesc: '',
  P03EFParamDesc: 'Setpoint (Out 1)',
  P04EFParamDesc: 'Isteresi / Banda proporzionale (Out 1)',
  P05EFParamDesc: 'Frequenza massima (Out 1)',
  P06EFParamDesc: '',
  P07EFParamDesc: '',
  P08EFParamDesc: '',
  P09EFParamDesc: '',
  P10EFParamDesc: 'Setpoint (Out 2)',
  P11EFParamDesc: 'Isteresi / Banda proporzionale (Out 2)',
  P12EFParamDesc: 'Frequenza massima (Out 2)',
  P13EFParamDesc: '',
  P14EFParamDesc: '',
  P15EFParamDesc: '',
  P16EFParamDesc: '',
  P17EFParamDesc: '',
  P18EFParamDesc: '',
  P19EFParamDesc: '',
  P20EFParamDesc: '',
  P21EFParamDesc: '',
  P22EFParamDesc: '',
  P23EFParamDesc: '',
  P24EFParamDesc: '',
  P25EFParamDesc: '',
  P26EFParamDesc: '',
  P27EFParamDesc: '',
  P28EFParamDesc: '',
  P29EFParamDesc: '',
  P30EFParamDesc: '',
  P31EFParamDesc: '',
  P32EFParamDesc: '',
  P33EFParamDesc: '',
  P34EFParamDesc: '',
  eventsLog: 'LOG Eventi',
  events: 'Eventi',
  alarm_new_stop: 'Strumento OFF',
  alarm_new_inputCadOff: 'Ingresso CAD (OFF)',
  alarm_new_flow: 'Allarme Flow',
  alarm_new_urOr: 'Allarme UR/OR',
  alarm_new_lowPHLvl: 'Allarme Min livello pH',
  alarm_new_lowCl2Lvl: 'Allarme Min livello Cl2',
  alarm_new_maxPHDosage: 'Allarme Max dosaggio pH',
  alarm_new_maxCl2Dosage: 'Allarme Max dosaggio Cl2',
  alarm_new_pHMin: 'Avviso minimo pH',
  alarm_new_phMax: 'Avviso massimo pH',
  alarm_new_cl2Min: 'Avviso minimo Cl2',
  alarm_new_cl2Max: 'Avviso massimo Cl2',
  alarm_new_clock: 'Timer',
  alarm_new_maxTimeK1: 'Allarme Max TK1',
  alarm_new_maxTimeK2: 'Allarme Max TK2',
  alarm_new_maxTimeK4: 'Allarme Max TK4',
  alarm_new_maxTimeK5: 'Allarme Max TK5',
  alarm_new_redox: 'Allarme Redox',
  alarm_new_zeroChlorine: 'Allarme 0 Cl2',
  output_mixer2: 'Mixer 2',
  alarm_extConsensus: 'Consenso esterno',
  alarm_lvl1MinAlarm: 'Allarme Min livello 1',
  alarm_lvl2MinAlarm: 'Allarme Min livello 2',
  alarm_lvl3MinAlarm: 'Allarme Min livello 3',
  alarm_off: 'Off',
  alarm_new_maintenance: 'Rich. Manutenzione',
  alarm_new_timer: 'Timer control',
  alarm_new_lvl: 'Allarme Livello',
  alarm_new_extcons: 'Allarme Ext.Cons',
  alarm_new_internalTemp: 'Allarme temperatura interna',
  alarm_new_clockKO: 'Allarme Orologio KO',
  alarm_new_sampleWater: 'Sample water',
  alarm_new_maxDoseTime: 'Allarme Max Dosaggio',
  alarm_new_maintenancePump1: 'Manutenzione P1',
  alarm_new_maintenancePump2: 'Manutenzione P2',
  alarm_new_urOrMeasure1: 'Allarme UR/OR Misura 1',
  alarm_new_urOrMeasure2: 'Allarme UR/OR Misura 2',
  alarm_new_lvl1: 'Allarme Livello 1',
  alarm_new_lvl2: 'Allarme Livello 2',
  alarm_new_maxDosageTimeOut1: 'Allarme MAX T P1',
  alarm_new_maxDosageTimeOut2: 'Allarme MAX T P2',
  statuses: 'Stati',
  theme: 'tema',
  toolStatus: 'Stato strumento',
  minWarningLimit: 'Valore ideale min',
  maxWarningLimit: 'Valore ideale max',
  canNotSaveOfflineOrErrModule:
    'non è possibile salvare le impostazioni quando il modulo è offline o è in errore di comunicazione',
  ew2PoolInterfaceName: 'Evolution EW² Pool',
  ew2Pool_pHOrpTcInterfaceName: 'EW² Pool pH/ORP/T',
  ew2Pool_pHCacTcInterfaceName: 'EW² Pool pH/CAC/T',
  ew2Pool_pHCleTcInterfaceName: 'EW² Pool pH/CLE/T',
  nova2Pool_pHOrpTcInterfaceName: 'Nova² Pool pH/ORP/T',
  nova2Pool_pHCacTcInterfaceName: 'Nova² Pool pH/CAC/T',
  nova2Pool_pHCleTcInterfaceName: 'Nova² Pool pH/CLE/T',
  alarm_minThresholdM1: 'E01 soglia minima M1',
  alarm_maxThresholdM1: 'E02 soglia massima M1',
  alarm_lvl1m1: 'E03 level 1 M1',
  alarm_lvl2m1: 'E04 level 2 M1',
  alarm_underRangeM1: 'E05 under range M1',
  alarm_overRangeM1: 'E06 over range M1',
  alarm_maxDosageM1: 'E07 massimo dosaggio M1',
  alarm_minThresholdM2: 'E09 soglia minima M2',
  alarm_maxThresholdM2: 'E10 soglia massima M2',
  alarm_lvl1m2: 'E11 level 1 M2',
  alarm_lvl2m2: 'E12 level 2 M2',
  alarm_underRangeM2: 'E13 under range M2',
  alarm_overRangeM2: 'E14 over range M2',
  alarm_maxDosageM2: 'E15 massimo dosaggio M2',
  alarm_sampleFlow: 'E18 flusso campione',
  alarm_pHPriority: 'E19 pH priority',
  output_pfmM1: 'DO1 - PFM M1',
  output_pfmM2: 'DO2 - PFM M2',
  output_outM1: 'DO10 - OUT M1',
  output_flocculant: 'DO7 - FLOCCULANTE',
  output_outM2: 'DO11 - OUT M2',
  output_antiAlgae: 'DO8 - ANTIALGA',
  output_poolRelayAlarm: 'DO9 - RELE’ ALLARME',
  input_lvl1m1: 'DI1 - Livello 1 M1',
  input_lvl2m1: 'DI2 - Livello 2 M1',
  input_externalConsent: 'DI3 - Consenso esterno',
  input_ecoMode: 'DI4 - Eco mode',
  input_sampleWater: 'DI6 - Samplewater',
  input_lvl1m2: 'DI8 - Livello 1 M2',
  input_lvl2m2: 'DI9 - Livello 2 M2',
  setpointM1OnOffParamDesc: 'Setpoint M1 On/Off',
  propBandM1OnOffParamDesc: 'Isteresi M1 On/Off',
  dosageDirM1OnOffParamDesc: 'Direzione Dosaggio M1 On/Off',
  maxDosageTimeM1OnOffParamDesc: 'Tempo MAX Dosaggio M1 On/Off',
  setpointM1PwmParamDesc: 'Setpoint M1 PWM',
  propBandM1PwmParamDesc: 'Banda Proporzionale M1 PWM',
  dosageDirM1PwmParamDesc: 'Direzione Dosaggio M1 PWM',
  maxDosageTimeM1PwmParamDesc: 'Tempo MAX Dosaggio M1 PWM',
  setpointM2OnOffParamDesc: 'Setpoint M2 On/Off',
  setpointM2PwmParamDesc: 'Setpoint M2 PWM',
  setpointM1PfmParamDesc: 'Setpoint M1 PFM',
  propBandM1PfmParamDesc: 'Banda Proporzionale M1 PFM',
  dosageDirM1PfmParamDesc: 'Direzione Dosaggio M1 PFM',
  maxDosageTimeM1PfmParamDesc: 'Tempo MAX Dosaggio M1 PFM',
  minFreqM1ParamDesc: 'Frequenza minima M1',
  maxFreqM1ParamDesc: 'Frequenza massima M1',
  minLimitM1ParamDesc: 'Limite MIN M1',
  maxLimitM1ParamDesc: 'Limite MAX M1',
  propBandM2OnOffParamDesc: 'Isteresi M2 On/Off',
  dosageDirM2OnOffParamDesc: 'Direzione Dosaggio M2 On/Off',
  maxDosageTimeM2OnOffParamDesc: 'Tempo MAX Dosaggio M2 On/Off',
  propBandM2PwmParamDesc: 'Banda Proporzionale M2 PWM',
  dosageDirM2PwmParamDesc: 'Direzione Dosaggio M2 PWM',
  maxDosageTimeM2PwmParamDesc: 'Tempo MAX Dosaggio M2 PWM',
  setpointM2PfmParamDesc: 'Setpoint M2 PFM',
  propBandM2PfmParamDesc: 'Banda Proporzionale M2 PFM',
  dosageDirM2PfmParamDesc: 'Direzione Dosaggio M2 PFM',
  maxDosageTimeM2PfmParamDesc: 'Tempo MAX Dosaggio M2 PFM',
  minFreqM2ParamDesc: 'Frequenza minima M2',
  maxFreqM2ParamDesc: 'Frequenza massima M2',
  setpointEcoModeParamDesc: 'Setpoint Eco/Boost mode',
  udmTempParamDesc: 'UDM Temperature',
  maxLimitM2ParamDesc: 'Limite MAX M2',
  minLimitM2ParamDesc: 'Limite MIN M2',
  acidification: 'acidificazione',
  alkalization: 'alcalinizzazione',
  dechlorination: 'declorazione',
  chlorination: 'clorazione',
  output_out230VM2: 'DO6 - OUT_230V M2',
  output_out230VM1: 'DO5 - OUT_230V M1',
  output_phMagnet: 'stato magnete pH',
  output_clMagnet: 'stato magnete Cl',
};
